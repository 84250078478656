import React from 'react';
import {useTranslation} from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { ReactComponent as FetchMailIcon } from '../../fetchmail.svg'
import { ReactComponent as RenovationInfoIcon } from '../../renovation-info.svg';
import { ReactComponent as ImgUploadIcon } from '../../img-upload.svg'
import { ReactComponent as PoiUploadIcon } from '../../poi-upload.svg'

const StyledModal = styled(Modal)`
  text-align: center;
  
  .modal-content {
    max-height: 90vh;
    overflow: scroll;
    overflow-x: hidden;
    border: none;
  }

  .modal-header, .modal-body, .modal-footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  h3 {
    margin: 16px 0 12px;
  }
  .modal-header {
    border: none;
    padding-top: 16px;
  }

  .tip {
    margin: 32px 0px 40px;
    color: rgb(0, 8, 32);
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    text-align: left;
    padding: 8px 12px;
    background-color: rgb(207, 224, 251);
    border-radius: 4px;
    svg {
      margin-right: 8px;
    }
  }

  .content-boxes {
    & > div {
      background-color: white;
      border: 1px solid #dfe2e6;
      margin: 1rem 0;
      padding: 2rem;

      svg {
        margin-bottom: 1rem;
      }

      h2 {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .modal-footer {
    justify-content: center;
  }
`

function EmailConfirmationModal({ show, setShow }) {
  const { t } = useTranslation('common');

  const handleClose = () => {
    setShow(prev => false);
    // Replace in window.location.pathname: review => view
    // and redirect to view url.
    let redirectUrl = window.location.pathname;
    redirectUrl = redirectUrl.replace('review', 'view');
    window.location.replace(redirectUrl);
  }

  return (
    <StyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>

      </Modal.Header>
      <Modal.Body>
        <FetchMailIcon />
        <h3>{t('review.modaltitle')}</h3>
        <p>{t('review.modalinfo1')}
          <br></br>{t('review.modalinfo2')}
          <br></br>
          <br></br>
          {t('review.modalinfo3')}
        </p>
        <p className='tip'>
          <RenovationInfoIcon />
          <span>
            <b>{t('review.modalinfotiptitle')}
            </b>&nbsp;
            {t('review.modalinfotip')}
          </span>
        </p>

        <div className='content-boxes'>
          <div>
            <ImgUploadIcon />
            <h2>{t('review.modalcontent1title')}</h2>
            <p>{t('review.modalcontent1text')}</p>
            <a href={`https://www.holidaycheck.de/wcf/hotel/${window.drupalSettings.hcpw.hotel_id}/image`}>
              {t('review.modalcontent1upload')}
              <i className='icon icon-right-arrow-line'></i>
            </a>
          </div>

          <div>
            <PoiUploadIcon />
            <h2>{t('review.modalcontent2title')}</h2>
            <p>{t('review.modalcontent2text')}</p>
            <a href='https://www.holidaycheck.de/wcf/poireview/'>
              {t('review.modalcontent2link')}
              <i className='icon icon-right-arrow-line'></i>
            </a>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t('review.modalclose')}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
}
export default EmailConfirmationModal