import React from "react";
import styled from 'styled-components'
import Stack from 'react-bootstrap/Stack'

const StyledRecommendationSuns = styled(Stack)`
  align-items: center;
  justify-content: center;

  .icon-sun {
    height: 24px;
    &::before {
      font-size: 24px;
    }
  }

  .rating-suns {
    padding-right: 7px;
  }
`

export function RecommendationSuns({ Rating, showMaxRating = true }) {
  let ratingRounded;
  let ratingString;

  // Prevent exception when Rating is null
  // this can happen, if the hotel haven't got any reviews yet.
  if (Rating === null) {
    Rating = 0;
  }

  // Overall Ratings are float values, Caterogry Ratings are Integers
  if (!Number.isInteger(Rating)) {
    ratingRounded = Math.round(Rating);
    ratingString = Rating.toFixed(1).toString();
  } else {
    ratingRounded = Rating;
    ratingString = Rating.toString() + '.0';
  }

  if (showMaxRating) {
    ratingString += ' / 6';
  }

  let renderOutput = '';
  for (let i = 0; i < 6; i++) {
    if (i < ratingRounded) {
      renderOutput += '<span class="sun-full"><i class="icon icon-sun"></i></span>';
    } else {
      renderOutput += '<span class="sun-empty"><i class="icon icon-sun"></i></span>';
    }
  }

  return (
    <StyledRecommendationSuns direction="horizontal">
      <div className='rating-suns' dangerouslySetInnerHTML={{ __html: renderOutput }} />
      <span>
        {ratingString}
      </span>
    </StyledRecommendationSuns>
  );
}
