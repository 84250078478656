import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const StyledImprintFooter = styled.section`
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  margin-top: 6rem;
  border-top: solid 1px ${props => props.theme.colors.gray};


  
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.linkblue};
    padding: 0 11px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }

    &:hover {
      color: #23527c;
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    & > a {
      padding: 0.5rem 0;
    }
  }
`

export function ViewFooter() {
  const { t } = useTranslation('common');

  // TODO: terms of use link for en
  // @see https://tojio.teamwork.com/tasks/22373804?c=9894067

  let privacyUrl = '//www.holidaycheck.de/datenschutz';
  if (window.drupalSettings.path.currentLanguage === 'en') {
    privacyUrl = privacyUrl + '?lang=en';
  }

  return (
    <>
      <StyledImprintFooter className="imprint-footer">
        <a href="//www.holidaycheck.de/nutzungsbedingungen" target="_blank" rel="noreferrer">
          {t('viewfooter.termsofuse')}
        </a>
        <a href={privacyUrl} target="_blank" rel="noreferrer">
          {t('viewfooter.privacy')}
        </a>
        <a href="https://www.holidaycheck.de/assets/documents/sorting.pdf" target="_blank" rel="noreferrer">
          {t('viewfooter.consumerinfo')}
        </a>
      </StyledImprintFooter>
    </>
  );
}