import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

export default class TextArea extends React.Component {

  handleChange = event => {
    this.props.form.setFieldValue(this.props.field.name, this.props.field.value);
  }

  render() {
    return (
      <div className="text-area" style={{ marginBottom: '-5.6px' }}>
        <label htmlFor={this.props.field.name}>{this.props.label}</label>
        <Field
          as='textarea'
          name={this.props.field.name}
          id={this.props.field.name}
          rows={this.props.rows}
          placeholder={this.props.placeholder}
        />
        <p className="help-paragraph">
          {this.props.help}
        </p>
        <ErrorMessage name={this.props.field.name} component={TextError} />
      </div>
    );
  }
}