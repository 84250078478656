import React from "react";
import { withTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import { SunRatingTexts } from "../../common/Mappings";
import TextError from "./TextError";
import styled from "styled-components";
import RadioButtons from "./RadioButtons";

const StyledSunRating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &> * {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
  }
  .radio-options {
  display: flex;
  cursor: auto;
  justify-content: center;
  padding: 0;
  border-right: ${props => props.theme.borders.default};

  > * {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .radio-option {
    border: none;
    padding: 0;

    i::before {
      font-size: 2.85714rem;
      line-height: 80px;
      color: #dfe2e6;
      padding-left: 0.71429rem;
    }
    &.sun-hover {
      i::before {
        color: #51a9e0 !important;
      }
    }
    &.sun-active {
      i::before {
        color: #F0C400;
      }
    }
  }
  }
`

class SunRating extends React.Component {

  onMouseLeaveWrapper = event => {
    let wrapperDiv = event.target.closest('.sun-rating');
    let radioOptionDivs = wrapperDiv.querySelectorAll('.radio-option');

    let checkedRadioOptionDiv = null;
    radioOptionDivs.forEach((radioOptionDiv) => {
      radioOptionDiv.classList.remove("sun-hover")
      if (radioOptionDiv.querySelector('input').checked) {
        checkedRadioOptionDiv = radioOptionDiv;
      }
    });

    var helpElement = document.getElementById(this.props.name + 'suntext');
    if (checkedRadioOptionDiv) {
      helpElement.innerHTML = checkedRadioOptionDiv.querySelector('input').getAttribute('data-selection-text')
    }
    else {
      helpElement.innerHTML = this.props.t('review.sunratinghelp')
    }
  }

  handleRadioButtons = event => {
    this.props.formikProps.handleChange(event);

    let targetRadioOptionDiv = event.target.closest('.radio-option');
    let wrapperDiv = event.target.closest('.sun-rating');
    let radioOptionDivs = wrapperDiv.querySelectorAll('.radio-option');

    radioOptionDivs.forEach((radioOptionDiv) => radioOptionDiv.classList.remove("sun-active"));

    let divAddClassActive = true;
    radioOptionDivs.forEach((radioOptionDiv) => {
      if (divAddClassActive) {
        radioOptionDiv.classList.add("sun-active");
      }
      if (radioOptionDiv === targetRadioOptionDiv) {
        divAddClassActive = false;
      }
    });
  }

  render() {

    // Name of a sunrating is always "valuation.SOMETHING.rating"
    // => split name and check formik errors props.
    const nameArray = this.props.name.split(".");
    let errorText = null;
    if (nameArray[0] in this.props.formikProps.errors &&
      nameArray[1] in this.props.formikProps.errors.valuations) {
      errorText = this.props.formikProps.errors.valuations[nameArray[1]].rating;
    }

    return (
      <>
        <StyledSunRating
          className="sun-rating radio-option"
          onMouseLeave={event => this.onMouseLeaveWrapper(event)}
        >
          <RadioButtons
            label=''
            name={this.props.name}
            options={[
              { key: this.props.name + '1', value: '1', iconClass: "icon icon-sun", dataSelectionText: this.props.t('review.' + SunRatingTexts[1]), mouseenter: true },
              { key: this.props.name + '2', value: '2', iconClass: "icon icon-sun", dataSelectionText: this.props.t('review.' + SunRatingTexts[2]), mouseenter: true },
              { key: this.props.name + '3', value: '3', iconClass: "icon icon-sun", dataSelectionText: this.props.t('review.' + SunRatingTexts[3]), mouseenter: true },
              { key: this.props.name + '4', value: '4', iconClass: "icon icon-sun", dataSelectionText: this.props.t('review.' + SunRatingTexts[4]), mouseenter: true },
              { key: this.props.name + '5', value: '5', iconClass: "icon icon-sun", dataSelectionText: this.props.t('review.' + SunRatingTexts[5]), mouseenter: true },
              { key: this.props.name + '6', value: '6', iconClass: "icon icon-sun", dataSelectionText: this.props.t('review.' + SunRatingTexts[6]), mouseenter: true }
            ]}
            onChange={event => {
              this.handleRadioButtons(event);
            }}
            style={{ visibility: "hidden", height: "0", width: "0" }}
            hasErrorClass={errorText ? ' validation-error' : ''}
          />
          <div>
            <span
              id={this.props.name + 'suntext'}
              className="sun-rating-text"
            >
              {this.props.t('review.sunratinghelp')}
            </span>
          </div>
        </StyledSunRating>
        <ErrorMessage name={this.props.name} component={TextError} />
      </>
    );
  }
}

export default withTranslation('common')(SunRating);