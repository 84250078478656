import React from "react";
// @see https://github.com/FilipChalupa/collapsible-react-component?ref=reactjsexample.com
import { Collapsible } from "collapsible-react-component";
import "collapsible-react-component/dist/index.css";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const StyledCollapsibleButton = styled.a`
  color: ${props => props.theme.colors.linkblue};

  &:hover {
    color: #51a9e0;
  }
`

export function CollapsibleReview({ review, ownerComment, handleOpen }) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const buttonOpenText = t('viewreviews.buttonopen') + '<i class="icon icon-down-arrow-line"></i>';
  const buttonCloseText = t('viewreviews.buttonclose') + '<i class="icon icon-up-arrow-line"></i>';
  const [buttonText, setButtonText] = useState(buttonOpenText);

  function handleClick() {
    setButtonText(open ? buttonOpenText : buttonCloseText);
    handleOpen(prev => !prev)
  }

  return (
    <>
      <Collapsible
        open={open}
        type='revealBottomFirst'
      >
        {review}
        {ownerComment}
      </Collapsible>
      <StyledCollapsibleButton
        type="button"
        onClick={() => {
          setOpen(!open);
          handleClick();
        }}
        dangerouslySetInnerHTML={{ __html: buttonText }}
      >
      </StyledCollapsibleButton>
    </>
  );
}