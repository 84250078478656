import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import {APITracker, useAPIHotel} from "../common/ApiConnector";
import { ErrorMessage, Field } from "formik";
import TextError from "../review/elements/TextError";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import i18n from "../../i18n";
import styled from "styled-components";

const initialValues = {
  emad: '',
  acceptemail: false,
  // Spam protection, this field is hidden.
  homepage: ''
}

export const createAtmValidationSchema = () => {
  return Yup.object().shape({
    emad: Yup
      .string()
      .email(i18n.t('common:review.emailinvalid'))
      .required(i18n.t('common:review.emailrequired')),
    acceptemail: Yup
      .boolean()
      .required(i18n.t('common:atm.acceptemailrequired'))
      .oneOf([true], i18n.t('common:atm.acceptemailrequired')),
    // Spam protection field "homepage" MUST be emtpy.
    homepage: Yup.object().shape({}).noUnknown()
  })
};

window.AtmValidationSchema = createAtmValidationSchema();

const StyledModal = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 65%;
    margin: auto;

    .return-message {
      color: green;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .main-container {
      background-color: white;
      box-shadow: #00000075 0px 1px 14px 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4rem 5rem;

      .header-logo {
        padding-bottom: 4rem;
      }

      .main-content {
        h1 {
          margin-bottom: 5rem;
        }
        .atm-info-text {
          margin-bottom: 3rem;
        }

        form > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          &:first-child {
            button {
              appearance: none;
              background-color: #0057a4;
              color: white;
              text-transform: uppercase;
              order: 2;
              margin-left: 50px;
              width: 30%;
            }
            & > * {
              padding: 14px 40px;
              border-radius: 30px;
            }

            .text-error {
              order: 3;
              padding-left: 0;
              padding-bottom: 0;
            }
            input {
              width: calc(70% - 50px);
              order: 1;
              height: 100%;
              border: none;
              box-shadow: 0px 1px 4px 1px #80808042;

              &:focus, &:active, &:target {
                outline: 1px solid black;
              }
            }
            }
          
          &:last-child {
            & > label {
              display: flex;
              color: gray;
              padding-top: 40px;
              flex-wrap: wrap;

              > div {
                display: flex;
              }
              p {
                margin-right: 50px;
                font-size: 12px;
              }
              input {
                margin-right: 15px;
                height: 27px;
                width: 15px;
              }
              .text-error {
                flex-basis: 100%;
              }
            }
          }
          }
        }
      }

      @media (max-width: 1199px) {
        width: 80%;
      }

      @media (max-width: 991px) {
        width: 100%;

        .main-container {
          padding: 4rem 3rem;
        }
      }

      @media (max-width: 700px) {
        form {
          & > :first-child > * {
            width: 100%;
            flex-basis: 100%;

            &:is(button) {
              margin-top: 30px;
              margin-left: 0px !important;
              order: 3 !important;
            }

            &:is(.text-error) {
              order: 2 !important
            }
          }
          & > :last-child label p {
            margin-right: 0 !important;
          }
        }
      }
          }
`

export function AtmForm() {
  const { HotelInfo } = useAPIHotel();
  const { t } = useTranslation('common');
  const [atmServiceReturn, setAtmServiceReturn] = useState(null);

  const hcLogoUrl = window.drupalSettings.hcpw.href + '/themes/custom/hcpw_base/img/logo/svg/hc_logo-0650e80df9.svg';

  // Save data in drupalSettings for easier access.
  window.drupalSettings.hcpw.hotel_id = HotelInfo.id;
  window.drupalSettings.hcpw.hotel_name = HotelInfo.name;

  const filteredCountry = HotelInfo.parents.filter(destination =>
    destination.destinationType === 'COUNTRY'
  )[0].name;
  window.drupalSettings.hcpw.hotel_country = filteredCountry;

  // Show content of AtmServiceReturn for a few seconds.
  const handleSubmission = () => {
    setTimeout(() => {
      setAtmServiceReturn(null);
    }, 10000)
  }

  const onSubmit = (values, submitProps) => {
    // TODO remove log
    //console.log('submit values', values)
    //console.log('submitProps', submitProps)

    // Define GET request values.
    const requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    };

    // Endpoint used to send atm data.
    const API_GET_ATM = window.drupalSettings.hcpw.href + '/atm-service'
    const queryString = '/?emad=' + values.emad + '&huuid=' + window.drupalSettings.hcpw.hotel_id + '&hname=' + window.drupalSettings.hcpw.hotel_name;

    // TODO remove log
    //console.log('executing fetch GET request:', API_GET_ATM + queryString)

    fetch(API_GET_ATM + queryString, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        // check for error response
        if (!response.ok) {
          // Get error message from body or default to response status.
          const error = (data && data.message) || response.status;
          console.error('GET Request: There was an error!', error);
          return Promise.reject(error);
        }
        console.log('GET request successful, result:', data);
        setAtmServiceReturn(data.message);
        console.log('ATM Service Return:', data.message)
        if (data.message === 'atm.successmessage') {
          // Track ATM only, if ATM emad entry was successful.
          APITracker('ATM');
        }
      })
      .catch(error => {
        console.error('GET Request: There was an error!', error);
      })
      .finally(() => {
        submitProps.setSubmitting(false);
        submitProps.resetForm();
      })

  }

  // Change privacy url to english.
  let privacyUrl = '//www.holidaycheck.de/datenschutz';
  if (window.drupalSettings.path.currentLanguage === 'en') {
    privacyUrl = privacyUrl + '?lang=en';
  }
  // Take placeholder image, if hotel has no profile picture.
  let profilePicture = HotelInfo.profilepicture;
  if (!profilePicture) {
    profilePicture = "https://www.holidaycheck.de/public/assets/img/hotel-index-no-picture-placeholder.svg";
  }

  return (
    <StyledModal>
      <div id="background-image" style={{
        filter: `blur(10px)`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        backgroundImage: `url(${profilePicture})`,
        position: `absolute`,
        top: `0`,
        bottom: `0`,
        left: `0`,
        right: `0`,
        zIndex: `-1`,
      }}>
      </div>

      <div className="main-container">
        <div className="header-logo">
          <img
            src={hcLogoUrl}
            alt={window.drupalSettings.hcpw.title}
          />
        </div>
        <div className="main-content">

          {atmServiceReturn ?
            <h2 className="return-message">{t(atmServiceReturn)}</h2> : ''
          }

          <h1>
            {HotelInfo.name}
          </h1>
          <p className="atm-info-text">
            {t('atm.infotext1')}
            <br></br>
            {t('atm.infotext2')}
          </p>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={window.AtmValidationSchema}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              return (
                <Form>
                  <div>
                    <Field
                      type="input"
                      name='emad'
                      size="60"
                      maxLength="60"
                      placeholder={"E-Mail"}
                      className={formikProps.errors.emad ? ' validation-error' : ''}
                    />
                    <ErrorMessage name="emad" component={TextError} />

                    <button
                      type="submit"
                      className=""
                      disabled={formikProps.isSubmitting}
                      onClick={handleSubmission}
                    >
                      {t('atm.submit')}
                    </button>
                  </div>
                  <div>

                    <label>
                      <div>
                        <Field
                          type="checkbox"
                          name='acceptemail'
                          className={formikProps.errors.acceptemail ? ' validation-error' : ''}
                        />
                        <p>{t('atm.accepttext')} <a href={privacyUrl} target="_blank" rel="noreferrer">{t('atm.privacylink')}.</a></p>
                      </div>
                      <ErrorMessage name="acceptemail" component={TextError} />
                    </label>

                    <Field
                      type="input"
                      name='homepage'
                      size="30"
                      maxLength="60"
                      hidden={true}
                    />
                  </div>

                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </StyledModal>
  );
}
