import { useTranslation } from 'react-i18next';

export function GetLocaleString() {
  let locale = 'de-DE';
  if (window.drupalSettings.path.currentLanguage === 'en') {
    locale = 'en-GB';
  }
  return locale;
}

export function MapTravelDate(timestamp) {
  try {
    let travelDate = new Date(timestamp);
    let options = { month: 'long', year: 'numeric' };
    let formattedDate = travelDate.toLocaleDateString(GetLocaleString(), options)
    return (
      formattedDate
    );
  } catch (error) {
    return "unknown";
  }
}

export function MapAgeGroup(ageGroup) {
  const ageIdMapping = {
    "FROM_14_TO_18": "14-18",
    "FROM_19_TO_25": "19-25",
    "FROM_26_TO_30": "26-30",
    "FROM_31_TO_35": "31-35",
    "FROM_36_TO_40": "36-40",
    "FROM_41_TO_45": "41-45",
    "FROM_46_TO_50": "46-50",
    "FROM_51_TO_55": "51-55",
    "FROM_56_TO_60": "56-60",
    "FROM_61_TO_65": "61-65",
    "FROM_66_TO_70": "66-70",
    "FROM_71": "70 >",
  };

  if (ageIdMapping.hasOwnProperty(ageGroup)) {
    return (
      <>{ageIdMapping[ageGroup]}</>
    );
  }
  else {
    return "unknown";
  }
}

export function MapTraveledWith(traveledWith) {
  const { t } = useTranslation('common');
  const traveledIdMapping = {
    "COUPLE": t('viewreviews.couple'),
    "SINGLE": t('viewreviews.single'),
    "FRIENDS": t('viewreviews.friends'),
    "FAMILY": t('viewreviews.family'),
    "NOTSPECIFIED": t('viewreviews.notspecified'),
  };

  if (traveledIdMapping.hasOwnProperty(traveledWith)) {
    return (
      <>{traveledIdMapping[traveledWith]}</>
    );
  }
  else {
    return "unknown";
  }
}

export function MapTravelReason(travelReason) {
  const { t } = useTranslation('common');
  const travelReasonMapping = {
    "BEACH": t('review.reasonbeach'),
    "BUSINESS": t('review.reasonbusiness'),
    "CITY": t('review.reasoncity'),
    "HIKING_WELLNESS": t('review.reasonwellness'),
    "WINTER_SPORT": t('review.reasonwinter'),
    "OTHER": t('review.reasonother'),
  };

  if (travelReasonMapping.hasOwnProperty(travelReason)) {
    return (
      <>{travelReasonMapping[travelReason]}</>
    );
  }
  else {
    return "unknown";
  }
}

export function MapChildren(children) {
  const { t } = useTranslation('common');
  const childrenMapping = {
    "NO": t('review.childrenno'),
    "ONE": t('review.childrenone'),
    "TWO": t('review.childrentwo'),
    "THREE": t('review.childrenthree'),
    "FOUR": t('review.childrenfour'),
    "MORE": t('review.childrenmore'),
  };

  if (childrenMapping.hasOwnProperty(children)) {
    return (
      <>{childrenMapping[children]}</>
    );
  }
  else {
    return "unknown";
  }
}

export const SunRatingTexts = {
  1: "very poor",
  2: "poor",
  3: "rather poor",
  4: "generally good",
  5: "good",
  6: "excellent",
};

// export function MapSunRatingTexts(children) {
//   const { t } = useTranslation('common');
//   const sunRatingTextsMapping = {
//     1: t('review.very poor'),
//     2: t('review.poor'),
//     3: t('review.rather poor'),
//     4: t('review.generally good'),
//     5: t('review.good'),
//     6: t('review.excellent')
//   }

//   if (sunRatingTextsMapping.hasOwnProperty(children)) {
//     return (
//       <>{sunRatingTextsMapping[children]}</>
//     );
//   }
//   else {
//     return "unknown";
//   }
// }

export function MapImageCategory(category) {
  const { t } = useTranslation('common');
  const categoryMapping = {
    "3a002784-135b-3f9a-96d6-999a3704fa22": t('viewimages.catreligious'),
    "1f261fd2-90d5-3dc0-827c-df49a8986c05": t('viewimages.catmuseum'),
    "28a468cb-ba46-343a-96a2-6dc61bffc176": t('viewimages.catnaturezoo'),
    "aac97e5a-9f98-3eda-8b88-dc1cc262f23c": t('viewimages.catbuildingother'),
    "2c2db754-c5e6-3cae-9453-008c10ee568f": t('viewimages.cathistoricsites'),
    "23bb1786-40b4-3d15-b4cc-a2703355ba94": t('viewimages.catamusementpark'),
    "6a18a299-5d83-390d-b807-9ea3a37f656b": t('viewimages.catmarketbazar'),
    "edf1ca2b-91ed-370b-827c-ffbf5e114166": t('viewimages.catsightsother'),
    "9a27d500-f528-32f4-8550-d0791edd8022": t('viewimages.catmonument'),
    "85881e26-f949-320c-b301-2b774ac1db7a": t('viewimages.catcastlepalace'),
    "904bfa01-130b-30c4-a277-f8842374b488": t('viewimages.catdiscoclub'),
    "007a5887-03dc-3160-abc7-7687225ccd02": t('viewimages.catpubbar'),
    "52dd974a-b97b-3d1d-8c95-c6df9b4ec116": t('viewimages.catshowcabaret'),
    "78329c8f-dfbe-3262-ac11-eb934e1a3642": t('viewimages.catleisureother'),
    "837635d5-2447-33c6-a51c-4afb4522b6ec": t('viewimages.catsportsother'),
    "85404996-1ae0-305a-92a3-0fd9302a8bbd": t('viewimages.catrestaurant'),
    "cf0b7149-3853-3262-b77e-f48871acec75": t('viewimages.catrooms'),
    "daf15d00-5530-3b07-8d42-3af265fba546": t('viewimages.catgarden'),
    "4b931169-d193-38f3-8691-a2e6ee52e6cd": t('viewimages.catexterior'),
    "bcb8501a-b6c1-316d-9b54-d255603ed7f6": t('viewimages.catview'),
    "df54dfbe-aaa3-3e17-961c-e810b579ec9e": t('viewimages.catpool'),
    "cd3b82ee-6b74-32bf-8581-eadf3d1dbc44": t('viewimages.catentrance'),
    "3505ab54-84f6-3b1c-9350-1bc1dab64260": t('viewimages.catsportleisure'),
    "271b8130-2bea-35b8-a71d-d519c2a2299c": t('viewimages.catrestaurantbuffet'),
    "f8822977-e768-39db-8711-00df2d8167ff": t('viewimages.catbar'),
    "3cc4dbfd-9ff7-381a-b746-a19a30171ddc": t('viewimages.cathotelbeach'),
    "1984fcf5-d9d1-3c5b-88b8-e934ce398ede": t('viewimages.catothertheme'),
    "0a90dc32-5771-3900-a886-48a206d9c452": t('viewimages.catreefs'),
    "1c9336f8-120b-361d-baa4-acb4b0661c19": t('viewimages.catfish'),
    "fe1bfbe6-c415-3664-a19a-cd205e591d90": t('viewimages.catunderwater'),
    "3013d60a-9550-3cef-92ad-01ce65ec7ac4": t('viewimages.catbirds'),
    "50315ffc-8604-34e0-aa55-d2e7438c0eae": t('viewimages.catreptiles'),
    "6911911f-e361-3575-ac56-dd211d751571": t('viewimages.catinsects'),
    "80ef6722-6597-307e-aa56-14e79996d8f9": t('viewimages.catpets'),
    "30723908-e8ba-3bdf-9e3e-44b8bfed7730": t('viewimages.catsafari'),
    "26bd9ad6-1fb9-313b-b107-274391bbe2ec": t('viewimages.catzoo'),
    "2c50fe35-6f40-3f51-930e-ae64a4600762": t('viewimages.catanimals'),
    "09597a35-ef73-3f45-859f-8ab5ce48060f": t('viewimages.catsunset'),
    "dc07d68f-a4e7-3845-a781-b1d96f8d8c70": t('viewimages.catbeachcoast'),
    "d64188a5-00bb-3a32-bf1c-2ce3a4bd9267": t('viewimages.catmountainvolcano'),
    "63ba09ba-8fc7-33df-b8d2-1b8743b7d6ee": t('viewimages.catforestjungle'),
    "eca5a865-8ab7-3bf1-9ca0-af64aeac9ea5": t('viewimages.catcity'),
    "ed498095-772d-3765-9de5-b3af881d6fbc": t('viewimages.catwaters'),
    "fabc1a5c-8367-3e12-ba56-1aa7a0c5dfbd": t('viewimages.catcave'),
    "b4ba1465-403f-3ef6-ae34-4dc01ce9114d": t('viewimages.catlandscape'),
    "329cb4a6-7a63-333d-8e90-7482461ace63": t('viewimages.catcabin'),
    "764c2d6b-0463-3491-b0a2-85779bc841f5": t('viewimages.catdeck'),
    "b2620b95-8657-3a31-ab64-1350351f5d97": t('viewimages.catexterior'),
    "f5fa2489-b544-3e6d-84c9-5d58f4606eef": t('viewimages.catview'),
    "fb2a2659-6668-35a5-87d2-020323a0c721": t('viewimages.catrestaurantbuffet'),
    "cc32c78a-86f5-3662-a186-39e5b4cdf5fb": t('viewimages.catbar'),
    "e1eba341-59d7-365d-a55e-f2f572395749": t('viewimages.catentertainment'),
    "5feb4a42-11db-337c-b634-36a27da678c7": t('viewimages.catsportfacilities'),
    "b73136ee-af23-3b9c-91a2-14dbbdeff393": t('viewimages.catshippool'),
    "7fb26bf4-7b56-340d-8bc0-326fcf8a50e2": t('viewimages.catothertheme'),
    "a86b14d2-eef7-3f30-9b4d-edef456822b6": t('viewimages.catpersonal'),
    "090a2937-eea6-3925-913e-03e19fd50865": t('viewimages.catgroup'),
    "80fe02ff-3c41-3da3-ab90-c142264c8cfc": t('viewimages.catotherpeople'),
    "6db6f329-3d13-3ca3-8f2a-7e06c93d32c3": t('viewimages.catcarbus'),
    "c4b1f1dc-04d9-3627-8676-321784cfcb9f": t('viewimages.cattrain'),
    "b20e13fc-ddfb-386c-ace4-e7cc93e4890e": t('viewimages.catplane'),
    "8cf60eee-5321-3204-905d-751af1d0d96a": t('viewimages.catshipboat'),
    "b298f24f-3024-3dbf-a36a-22015f4a8c7c": t('viewimages.cattransport'),
  };

  if (categoryMapping.hasOwnProperty(category)) {
    return (
      <>{categoryMapping[category]}</>
    );
  }
  else {
    return "unknown";
  }
}
