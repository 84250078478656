import React from "react";
import { RecommendationDetail } from "./RecommendationDetail"
import { RecommendationSuns } from "./RecommendationSuns"

import styled from 'styled-components'

import Stack from 'react-bootstrap/Stack'

import { useTranslation } from "react-i18next";


const StyledRecommendation = styled(Stack)`
  padding-top: 14px;
`

const StyledNoRatingInfo = styled.p`
  margin: 0;
  color: red;
`

export function Recommendation({ Recommendation, Rating, percentage }) {
  const { t } = useTranslation('common');
  return (
    <StyledRecommendation
      direction="horizontal"
      className='hotel-info-recommendation'
    >
      {Recommendation !== null ? (
        <>
          <RecommendationDetail
            Recommendation={Recommendation}
            percentage={percentage}
          />
          <RecommendationSuns
            Rating={Rating}
          />
        </>
      ) : (
        <StyledNoRatingInfo>{t('viewheader.noreviews')}</StyledNoRatingInfo>
      )
      }
    </StyledRecommendation>
  );
}