import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledSubHeader = styled.div`
  padding-top: 2rem;
  text-align: center;
  h2 {
    text-align: center;
    box-sizing: border-box;
    color: inherit;
    display: inline-block;
    font-family: Open Sans,sans-serif;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.5rem;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    padding-bottom: 1.5rem;

  }
  > p {
    margin: 2rem 0 3rem;
  }
`
export default function SubHeader() {
  const { t } = useTranslation('common');

  return (
    <StyledSubHeader className="subheader-wrapper">
      <h2 className="yellow-underline">{t('review.subheadertitle')}</h2>
      <p>{t('review.subheadertext')}</p>
    </StyledSubHeader>
  );
}
