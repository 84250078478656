import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Select (props) {
  let { label, name, options, hasErrorClass, ...rest } = props
  if (!hasErrorClass) hasErrorClass = '';

  return (
    <div className={'select-control' + hasErrorClass}>
      <label htmlFor={name}>{label}</label>
      <Field
        as='select'
        id={name}
        name={name}

        {...rest}
      >
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          )
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Select