import React from "react";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import TextError from "./TextError";

export default function FirstName({ formikProps }) {
  const { t } = useTranslation('common');

  return (
    <div className="firstname-wrapper review-section">
      <h3>
        {t('review.firstname')} *
      </h3>
      <Field
        type="input"
        name='firstName'
        size="60"
        maxLength="50"
        className={formikProps.errors.firstName ? ' validation-error' : ''}
      />
      <ErrorMessage name="firstName" component={TextError} />
    </div>
  );
}
