import React from "react";

// Get award picture.
export function Award({awardInfo}) {
  if (awardInfo) {
    let currentYear = new Date();
    currentYear = currentYear.getFullYear();
    let hotelClass = '';
    let badgeImgUrl = '';

    if (awardInfo.year === currentYear) {
      if (awardInfo.category === 'HOLIDAYCHECK_GOLD_AWARD') {
        hotelClass = 'badge-top';
        badgeImgUrl = 'https://media.holidaycheck.com/data/image/common/hcaward/hcgold.svg';
      } else {
        hotelClass = 'badge-award';
        // TODO: get URL of svg award picture
        badgeImgUrl = 'https://www.holidaycheck.de/aktionen/upload/img/award-badge-' + currentYear + '_1x.png';
      }
      return (
        <div className={hotelClass}>
          <img
            src={badgeImgUrl}
            alt='Award Badge'
          />
        </div>
      );
    }
  } else {
    // No award for this hotel.
    return null;
  }
}