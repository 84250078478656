import React from "react";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import TextError from "./TextError";
import styled from "styled-components";

const StyledCityWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export default function City({ formikProps }) {
  const { t } = useTranslation('common');

  return (
    <StyledCityWrapper className="city-wrapper review-section">
      <h3>
        {t('review.city')}
      </h3>
      <Field
        type="input"
        name='userCity'
        size="60"
        maxLength="128"
        className={formikProps.errors.userCity ? ' validation-error' : ''}
      />
      <ErrorMessage name="userCity" component={TextError} />
    </StyledCityWrapper>
  );
}
