import { useEffect, useRef } from "react";

// Utility to run a function only once.
// @see https://www.perssondennis.com/articles/react-hook-use-run-once
// This is used with the tracking mechanism to avoid calling it
// multiple times when re-rendering components.

const useRunOnce = ({ fn, sessionKey }) => {
  const triggered = useRef(false);

  useEffect(() => {
    const hasBeenTriggered = sessionKey
      ? sessionStorage.getItem(sessionKey)
      : triggered.current;

    if (!hasBeenTriggered) {
      fn();
      triggered.current = true;

      if (sessionKey) {
        sessionStorage.setItem(sessionKey, "true");
      }
    }
  }, [fn, sessionKey]);

  return null;
};

export default useRunOnce;