import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const StyledHotelDetailsCategories = styled.ul`
  padding: 0;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;

    li {
      align-items: flex-start;
    }
    button {
      align-items: center !important;
      flex-direction: row !important;
      height: unset !important;
      padding: 10px 0 !important;

      i {
        font-size: 2rem !important;
        margin: 0 !important;
        margin-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
  }

  li {
    list-style-type: none;
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    appearance: none;
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${props => props.theme.borders.default};
    width: 100%;
    height: 95px;

    &:hover {
      border: ${props => props.theme.borders.inputHover};
      color: #51a9e0;
      
      i {
        color: #51a9e0;
      }
    }

    &.active {
      background: #FAE896;
      color: #665925;
      border-color: #d9bd43;

      i {
        color: #665925;
      }
    }

    i {
      margin-top: 12px;
      font-size: 2.85714rem;
      display: block;
      margin-bottom: 0.5rem;
      color: #254d66;
    }
  }
`

class HotelDetailsCategories extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      'toggle-cat-location': false,
      'toggle-cat-bed': false,
      'toggle-cat-service': false,
      'toggle-cat-food': false,
      'toggle-cat-leisure': false,
    };
  }

  handleClick(e) {
    if (e.target.nodename !== "BUTTON") {
      e.target = e.target.closest('button')
    }
    this.props.onClick(e.target.id);

    const current = this.state[e.target.id];
    const newState = this.state;
    newState[e.target.id] = !current;
    this.setState(newState);
  }

  render() {
    return (
      <StyledHotelDetailsCategories>
        <li>
          <button
            className={this.state['toggle-cat-location'] ? 'active' : null}
            type="button"
            id="toggle-cat-location"
            onClick={this.handleClick}
          >
            <i className="icon icon-location" />
            {this.props.t('review.hoteldetailscatlocation')}
          </button>
        </li>
        <li>
          <button
            className={this.state['toggle-cat-bed'] ? 'active' : null}
            type="button"
            id="toggle-cat-bed"
            onClick={this.handleClick}
          >
            <i className="icon icon-bed" />
            {this.props.t('review.hoteldetailscatrooms')}
          </button>
        </li>
        <li>
          <button
            className={this.state['toggle-cat-service'] ? 'active' : null}
            type="button"
            id="toggle-cat-service"
            onClick={this.handleClick}
          >
            <i className="icon icon-service" />
            {this.props.t('review.hoteldetailscatservice')}
          </button>
        </li>
        <li>
          <button
            className={this.state['toggle-cat-food'] ? 'active' : null}
            type="button"
            id="toggle-cat-food"
            onClick={this.handleClick}
          >
            <i className="icon icon-restaurant" />
            {this.props.t('review.hoteldetailscatfood')}
          </button>
        </li>
        <li>
          <button
            className={this.state['toggle-cat-leisure'] ? 'active' : null}
            type="button"
            id="toggle-cat-leisure"
            onClick={this.handleClick}
          >
            <i className="icon icon-recreation" />
            {this.props.t('review.hoteldetailscatleisure')}
          </button>
        </li>
      </StyledHotelDetailsCategories>
    );
  }
}

export default withTranslation('common')(HotelDetailsCategories);
