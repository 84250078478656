import styled from "styled-components";
import Stack from "react-bootstrap/esm/Stack";

const HotelInfoStack = styled(Stack)`
  margin-top: 20px;
  border: 1px solid #c2c7cc;
  background-color: white;
  padding: 0;

  .col {
    padding: 0;
  }

  .vstack {
    padding: 21px;
    align-items: flex-start;
  }

  img {
    width: 320px;
    height: 214px;
    padding: 0;
  }

  .location {
    color: ${props => props.theme.colors.blue};
  }

  .hotel-info-recommendation {
    .rating-percent {
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: ${props => props.theme.colors.blue};
      color: white;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-like {
        font-size: 24px;
        padding-right: 7px;
      }
    }
    .hstack {
      border: 1px solid ${props => props.theme.colors.gray};
      padding: 4px 14px;
    }
  }

  .badge-award, .badge-top {
    padding-right: 21px;
    img {
      height: 100px;
      width: 100px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    img {
      width: 100%;
      height: auto;
    }
  }
`

export default HotelInfoStack