import React from "react";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import TextError from "./TextError";

export default function ReviewTitle({ formikProps }) {
  const { t } = useTranslation('common');

  return (
    <div className="reviewtitle-wrapper review-section">
      <h3>
        {t('review.titleheading')} *
      </h3>
      <Field
        type="input"
        name='title'
        maxLength="50"
        className={formikProps.errors.title ? ' validation-error' : ''}
      />
      <p className="help-paragraph">
        {t('review.titlehelp')}
      </p>
      <ErrorMessage name="title" component={TextError} />
    </div>
  );
}
