import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

import { useAPIMedia } from "../common/ApiConnector";
import { MapImageCategory } from "../common/Mappings";
import { ImageGallery } from "./ImageGallery";

const StyledImages = styled.div`
  text-align: center;
  h3 {
    padding: 2rem 0 3rem 0;
  }
  .filter {
    margin-bottom: 3rem;
  }
`

export function ViewImages() {
  //const { MediaInfo, TotalMedia } = useAPIMedia();
  const { MediaInfo } = useAPIMedia();
  const { t } = useTranslation('common');

  // formattedTotals: currently not used.
  //const formattedTotals = TotalMedia.toLocaleString(GetLocaleString());

  const [filteredImages, setFilteredImages] = useState(MediaInfo);
  const filterByImageCategory = categoryValue => {
    if (categoryValue === t('viewimages.allcategories')) {
      setFilteredImages(MediaInfo);
    } else {
      setFilteredImages(
        MediaInfo.filter(media => {
          return media.categories[0].id === categoryValue
        })
      )
    }
  }
  const ImageCategoryOptions = Array.from(
    new Set(MediaInfo.map(media => media.categories[0].id))
  )

  return (
    <StyledImages>
      <h3 className="yellow-underline">
        {t('viewimages.title')}
      </h3>

      {/* Hint: totals currently not needed. */}
      {/* <p>{t('viewimages.info', { totals: formattedTotals, maxpicturecount: window.drupalSettings.hcpw.max_picture_count })}:</p> */}

      <div className='filter'>
        <span className="image-filter-label">{t('viewimages.category')}:</span>
        <select onChange={event => filterByImageCategory(event.target.value)}
                name='image-filter-select'
        >
          <option
            key="0"
            value={t('viewimages.allcategories')}
          >
            {t('viewimages.allcategories')}
          </option>

          {ImageCategoryOptions.map(category => {
            return <option
              key={category}
              value={category}
            >
              {MapImageCategory(category)}
            </option>
          })}
        </select>
      </div>

      <ImageGallery
        images={filteredImages}
      />
    </StyledImages>
  );
}