import React from "react";
import { useTranslation } from 'react-i18next';
import { useAPIHotel } from "../common/ApiConnector";

import styled from "styled-components";
import Col from "react-bootstrap/esm/Col";
import Stack from "react-bootstrap/esm/Stack";
import LogoRow from "../common/LogoRow";
import HotelInfoStack from "../common/StyledHotelInfoStack";

const StyledReviewTitle = styled.h1`
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;
  padding: 0 5rem;
  text-align: center;
  margin-top: 2.5rem;
  font-weight: 300;
`

const StyledHotelTitle = styled.h4`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
`

export function ReviewHeader() {
  const { HotelInfo } = useAPIHotel();
  const { t } = useTranslation('common');

  const filteredCity = HotelInfo.parents.filter(destination =>
    destination.destinationType === 'CITY'
  )[0].name;
  const filteredCountry = HotelInfo.parents.filter(destination =>
    destination.destinationType === 'COUNTRY'
  )[0].name;

  window.drupalSettings.hcpw.hotel_name = HotelInfo.name;

  return (
    <div className="review-section">
      <LogoRow />

      <StyledReviewTitle>{t('reviewheader.title')}</StyledReviewTitle>
      <HotelInfoStack direction="horizontal">
        <img
          src={HotelInfo.profilepicture}
          alt={HotelInfo.name}
          // Fallback placeholder on error, if there is no profile picture.
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // Prevent looping.
            currentTarget.src = "https://www.holidaycheck.de/public/assets/img/hotel-index-no-picture-placeholder.svg";
          }}
        />
        <Col>
          <Stack>
            <StyledHotelTitle>
              {HotelInfo.name}
            </StyledHotelTitle>

            <div>
              <i className="icon icon-location"></i>
              {filteredCity}, {filteredCountry}
            </div>
          </Stack>
        </Col>
      </HotelInfoStack>
    </div>
  );
}
