import React, { useContext, useState, useEffect, createContext } from "react";

const APIHotelContext = createContext();
const APIReviewContext = createContext();
const APIMediaContext = createContext();

const API_GET_HOTEL = 'https://api.hcpw.vahaha.han-solo.net/svc/api-hotel/v3/hotel'
const API_GET_REVIEW = 'https://api.hcpw.vahaha.han-solo.net/svc/api-hotelreview/v3/hotelreview'
const API_GET_MEDIA = 'https://api.hcpw.vahaha.han-solo.net/svc/api-media/v3/media'

export function APIHotelContextProvider({ children }) {
  const [HotelInfo, setHotelInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryData = {
      select: 'select=name,parents,reviewCalculations.overall,reviewCalculations.perAspectGroup,address,latestAward,renovation,fake',
      filter: 'filter=id:in:' + window.drupalSettings.hcpw.hotel_id,
      locale: 'locale=' + window.drupalSettings.path.currentLanguage,
    }
    const query = API_GET_HOTEL + '?' + queryData.select + '&' + queryData.filter + '&' + queryData.locale;

    const getHotelInfo = async () => {
      try {
        const response = await fetch(query, {
          headers: new Headers({
            'Partner-ID': window.drupalSettings.hcpw.partner_id,
          }),
        });

        const data = await response.json();
        if (response.status >= 400 && response.status < 600) {
          setError(response.status);
          console.error('Hotel-Request: Bad response from server.');
        }
        if (data.items[0]) {
          data.items[0].profilepicture = 'https://www.holidaycheck.de/main-photo-redirect/' + window.drupalSettings.hcpw.hotel_id;
          setHotelInfo(data.items[0]);
          setLoading(false);
        } else {
          console.error('Hotel-Request delivered no data items.');
        }
      } catch (error) {
        setError(error.message);
        console.error(error);
      }
    };

    getHotelInfo();
  }, []);

  if (error) return <div>error {error}</div>;
  if (loading) return '';

  return (
    <APIHotelContext.Provider value={{ HotelInfo }}>
      {children}
    </APIHotelContext.Provider>
  );
}

export function useAPIHotel() {
  const context = useContext(APIHotelContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export function APIReviewContextProvider({ children }) {
  const [ReviewInfo, setReviewInfo] = useState([]);
  const [TotalReviews, setTotalReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const maxReviewCount = Number(window.drupalSettings.hcpw.max_review_count);
    const queryData = {
      select: 'select=title,texts,ratings,recommendation,user,travelDate,traveledWith,ownerComment,proofedReservation',
      filter: 'filter=hotel.id:' + window.drupalSettings.hcpw.hotel_id,
      sort: 'sort=travelDate:desc,entryDate:desc',
      limit: 'limit=' + maxReviewCount,
      locale: 'locale=' + window.drupalSettings.path.currentLanguage,
    }
    const query = API_GET_REVIEW + '?' + queryData.select + '&' + queryData.filter + '&' + queryData.sort + '&' + queryData.limit + '&' + queryData.locale;

    const getReviewInfo = async () => {
      try {
        const response = await fetch(query, {
          headers: new Headers({
            'Partner-ID': window.drupalSettings.hcpw.partner_id,
          }),
        });

        const data = await response.json();
        if (response.status >= 400 && response.status < 600) {
          setError(response.status);
          console.error('Review-Request: Bad response from server.');
        }
        if (data.items[0]) {
          setReviewInfo(data.items);
          setTotalReviews(data.total);
          setLoading(false);
        } else {
          console.error('Review-Request delivered no data items.');
        }
      } catch (error) {
        setError(error.message);
        console.error(error);
      }
    };

    getReviewInfo();
  }, []);

  if (error) return <div>error {error}</div>;
  if (loading) return '';

  return (
    <APIReviewContext.Provider value={{ ReviewInfo, TotalReviews }}>
      {children}
    </APIReviewContext.Provider>
  );
}

export function useAPIReview() {
  const context = useContext(APIReviewContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export function APIMediaContextProvider({ children }) {
  const [MediaInfo, setMediaInfo] = useState([]);
  const [TotalMedia, setTotalMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const maxPictureCount = Number(window.drupalSettings.hcpw.max_picture_count);
    const queryData = {
      select: 'select=id,user,sourceType,travelDate,entryDate,title,description,categories',
      filter: 'filter=parents.id:' + window.drupalSettings.hcpw.hotel_id,
      sort: 'sort=travelDate:desc,entryDate:desc',
      limit: 'limit=' + maxPictureCount,
      locale: 'locale=' + window.drupalSettings.path.currentLanguage,
    }
    const query = API_GET_MEDIA + '?' + queryData.select + '&' + queryData.filter + '&' + queryData.sort + '&' + queryData.limit + '&' + queryData.locale;

    const getMediaInfo = async () => {
      try {
        const response = await fetch(query, {
          headers: new Headers({
            'Partner-ID': window.drupalSettings.hcpw.partner_id,
          }),
        });

        const data = await response.json();
        if (response.status >= 400 && response.status < 600) {
          setError(response.status);
          console.error('Media-Request: Bad response from server.');
        }
        if (data.items[0]) {
          setMediaInfo(data.items);
          setTotalMedia(data.total);
          setLoading(false);
        } else {
          console.error('Media-Request delivered no data items.');
        }
      } catch (error) {
        setError(error.message);
        console.error(error);
      }
    };

    getMediaInfo();
  }, []);

  if (error) return <div>error {error}</div>;
  if (loading) return '';

  return (
    <APIMediaContext.Provider value={{ MediaInfo, TotalMedia }}>
      {children}
    </APIMediaContext.Provider>
  );
}

export function useAPIMedia() {
  const context = useContext(APIMediaContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export function APITracker( trackingAction ) {

  // On trackingAction "ATM", set partnerId manually because
  // ATM is not bound to a partner - we currently use partner tojio
  // with partnerId 993.
  var partner = undefined;
  if (trackingAction === 'ATM') {
    partner = 'tojio';
  }
  else {
    if (window.drupalSettings.hcpw.partner) {
      partner = window.drupalSettings.hcpw.partner;
    }
    else {
      console.log('Tracking not possible, partner is missing.')
      return;
    }
  }


  //console.log('permissions hash:', window.drupalSettings.user.permissionsHash)
  //console.log('action:', trackingAction);
  //console.log('partner:', partner);
  //console.log('hname:', window.drupalSettings.hcpw.hotel_name);
  //console.log('hcountry:', window.drupalSettings.hcpw.hotel_country);

  // Define GET request values.
  const requestOptions = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  };

  // Endpoint used to send tracking data.
  const API_GET_TRACKER = window.drupalSettings.hcpw.href + '/tracker'

  // Construct query string.
  var queryString = '/?action=' + trackingAction +
    '&partner=' + partner +
    '&hname=' + window.drupalSettings.hcpw.hotel_name +
    '&hcountry=' + window.drupalSettings.hcpw.hotel_country +
    '&hash=' + window.drupalSettings.user.permissionsHash;

  // TODO remove log
  //console.log('executing fetch GET request:', API_GET_TRACKER + queryString)

  fetch(API_GET_TRACKER + queryString, requestOptions)
    .then(async response => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson && await response.json();
      // check for error response
      if (!response.ok) {
        // Get error message from body or default to response status.
        const error = (data && data.message) || response.status;
        console.error('Tracker GET Request: There was an error!', error);
        return Promise.reject(error);
      }
      //console.log('Tracker GET request successful, result:', data);
    })
    .catch(error => {
      console.error('Tracker GET Request: There was an error!', error);
    })
    .finally(() => {
    })
}