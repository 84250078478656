import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import RadioButtons from "./RadioButtons";

export default function TraveledWith({ formikProps }) {
  const { t } = useTranslation('common');

  const handleRadioButtons = event => {
    formikProps.handleChange(event);

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <div className="traveledwith-wrapper review-section">
      <h3>{t('review.traveledwithtitle')} *</h3>
      <RadioButtons
        label=""
        buttonsperrow={4}
        name="traveledWith"
        options={[
          { key: t('viewreviews.couple'), value: 'COUPLE', iconClass: "icon icon-review-couple", displayLabel: true, displayIcon: true },
          { key: t('viewreviews.family'), value: 'FAMILY', iconClass: "icon icon-review-family", displayLabel: true, displayIcon: true },
          { key: t('viewreviews.single'), value: 'SINGLE', iconClass: "icon icon-review-alone", displayLabel: true, displayIcon: true },
          { key: t('viewreviews.friends'), value: 'FRIENDS', iconClass: "icon icon-review-friends", displayLabel: true, displayIcon: true },
        ]}
        onChange={event => {
          handleRadioButtons(event);
        }}
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.traveledWith ? ' validation-error' : ''}
      />
      <ErrorMessage name="traveledWith" component={TextError} />
    </div>
  )
}
