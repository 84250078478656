import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import RadioButtons from "./RadioButtons";
import styled from "styled-components";

const StyledTravelReason = styled.div`
  .validation-error {
    .radio-option {
      &:nth-of-type(4) {
        border-right: 1px solid red;
      }
      &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
        border-bottom-color: #c2c7cc;

        &:hover {
          border-bottom-color: #51a9e0;
        }
      }
      &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8) {
        border-top-color: #c2c7cc;

        &:hover {
          border-top-color: #51a9e0;
        }
      }
      &:nth-of-type(5) {
        border-left: 1px solid red;
      }
    }
  }
`

export default function TravelReason({ formikProps }) {
  const { t } = useTranslation('common');

  const handleRadioButtons = event => {
    formikProps.handleChange(event);

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  // For option-values @see https://tojio.teamwork.com/#tasks/21760598?c=9957713
  return (
    <StyledTravelReason className="travelreason-wrapper review-section">
      <h3>{t('review.reasontitle')} *</h3>
      <RadioButtons
        label=""
        buttonsperrow={4}
        name="travelReason"
        options={[
          { key: t('review.reasonbeach'), value: 'BEACH', iconClass: "icon icon-beach", displayLabel: true, displayIcon: true },
          { key: t('review.reasonwellness'), value: 'WELLNESS', iconClass: "icon icon-travel-wellness", displayLabel: true, displayIcon: true },
          { key: t('review.reasoncity'), value: 'CITY', iconClass: "icon icon-travel-city", displayLabel: true, displayIcon: true },
          { key: t('review.reasonwinter'), value: 'WINTER_SPORT', iconClass: "icon icon-travel-winter", displayLabel: true, displayIcon: true },
          { key: t('review.reasonbusiness'), value: 'BUSINESS', iconClass: "icon icon-travel-business", displayLabel: true, displayIcon: true },
          { key: t('review.reasonactivity'), value: 'ACTIVE', iconClass: "icon icon-recreation", displayLabel: true, displayIcon: true },
          { key: t('review.reasonculture'), value: 'CULTURE', iconClass: "icon icon-travel-culture", displayLabel: true, displayIcon: true },
          { key: t('review.reasonother'), value: 'OTHER', iconClass: "icon icon-profile-edit", displayLabel: true, displayIcon: true },
        ]}
        onChange={event => {
          handleRadioButtons(event);
        }}
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.travelReason ? ' validation-error' : ''}
      />
      <ErrorMessage name="travelReason" component={TextError} />
    </StyledTravelReason>
  )
}
