import React from "react";

export function RecommendationDetail({Recommendation, percentage}) {
  if (percentage) {
    const recommendationPercent = Math.round(Recommendation * 100.0);
    return (
      <div className='rating-percent'>
        <i className="icon icon-like"></i>
        {recommendationPercent}%
      </div>
    );
  }
  else {
    return (
      <>
      {Recommendation ? (
          <div className='rating-author green'>
            <i className="icon icon-like"></i>
          </div>
        ) : (
          <div className='rating-author red'>
            <i className="icon icon-dislike"></i>
          </div>
        )}
      </>
    );
  }
}