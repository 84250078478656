import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import StyledMainButton from '../common/StyledMainButton'

const StyledFakeModal = styled(Modal)`
  .modal-content {
    border: solid 0.5rem #e8402D;
    border-radius: 0;
  }
  .modal-body {
    display: flex;
    padding: 2rem 0 3rem;

    .fakemodal-body-icon {
      flex-basis: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fakemodal-body-text {
      flex-basis: 66%;
      display: flex;
      flex-direction: column;
      padding-right: 2.5rem;

      .modal-title {
        font-size: 2rem;
        font-weight: 600;
        line-height: 0.75;
        padding: 1.5rem 0;
      }
      .text {
        margin-bottom: 1.5rem;
      }
      .button {
        justify-content: flex-start;

        a {
          font-size: 1.143rem;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .modal-body {
      flex-direction: column;
      padding: 2rem 1.5rem 1.5rem;

      .fakemodal-body-text {
        padding-right: 0;
      }

      .modal-title {
        font-size: 1.143rem !important;
        line-height: 1.5rem !important;
        padding: 1.5rem 0 0.5rem !important;
      }

      .button {
        width: 100%;

        a {
          flex-basis: 100%;
          text-align: center;
        }
      }
    }
  }
`

export default function FakeModal({ text }) {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className="hotel-header-modal"
        onClick={handleShow}
      >
        {t('viewheader.fakemoreinfo')}
      </button>

      <StyledFakeModal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        keyboard={false}>

        <Modal.Body>
          <div className="fakemodal-body-icon">
            <img
              src="https://www.holidaycheck.de/public/assets/img/alert-illustration.svg"
              alt="Alert Illustration"
            />
          </div>
          <div className="fakemodal-body-text">
            <Modal.Title>
              {t('viewheader.fakeinfotitle')}
            </Modal.Title>
            <p className='text'>
              {text}
            </p>
            <StyledMainButton className='button' gray onClick={handleClose}>
              {/* eslint-disable-next-line */}
              <a className="pull-left">
                {t('viewheader.fakeinfoclose')}
              </a>
            </StyledMainButton>
          </div>
        </Modal.Body>
      </StyledFakeModal>
    </>
  );
}
