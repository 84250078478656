import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import RadioButtons from "./RadioButtons";

export default function TravelChildren({ formikProps }) {
  const { t } = useTranslation('common');

  const handleRadioButtons = event => {
    formikProps.handleChange(event);

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <div className="travelchildren-wrapper review-section">
      <h3>{t('review.childrentitle')} *</h3>
      <RadioButtons
        label=""
        buttonsperrow={6}
        name="children"
        options={[
          { key: t('review.childrenno'), value: 'NO', displayLabel: true },
          { key: t('review.childrenone'), value: 'ONE', displayLabel: true },
          { key: t('review.childrentwo'), value: 'TWO', displayLabel: true },
          { key: t('review.childrenthree'), value: 'THREE', displayLabel: true },
          { key: t('review.childrenfour'), value: 'FOUR', displayLabel: true },
          { key: t('review.childrenmore'), value: 'MORE', displayLabel: true },
        ]}
        onChange={event => {
          handleRadioButtons(event);
        }}
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.children ? ' validation-error' : ''}
      />
      <ErrorMessage name="children" component={TextError} />
    </div>
  )
}
