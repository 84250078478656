import React from 'react'

import { withTranslation } from 'react-i18next';
import { Field } from "formik";

import Stack from 'react-bootstrap/esm/Stack';

import SunRating from "./elements/SunRating";
import TextArea from "./elements/TextArea";
import HotelDetailsCategories from "./elements/HotelDetailsCategories";
import styled from 'styled-components';

const StyledHotelDetailsInput = styled.div`
  border: ${props => props.theme.borders.default};
  margin-bottom: 1rem;

  .hstack .hoteldetails-suns .text-error {
    padding-bottom: 0.2rem;
  }

  @media (max-width: 767px) {
    .hstack {
      flex-direction: column;
      align-items: flex-start;

      .hoteldetails-suns {
        border-top: 1px solid #edf0f2;
        padding: 0.57143rem;
        width: 100%;
      }
    }
  }

  & > .hstack {
    justify-content: space-between;
    border: none;
    background-color: white;
    border-bottom: ${props => props.theme.borders.default};

    h3 {
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      i {
      font-size: 2.85714rem;
      color: #c2c7cc;
      margin: 0 15px;
      }
    }
    .hoteldetails-suns {
      text-align: center;
    }
    .sun-rating-text {
      margin-bottom: 10px;
    }
    .icon-sun {
      &::before {
          line-height: 60px !important;
        }
    }
  }

  .sun-rating {
    flex-direction: column;
    border: none;
    height: unset;

    .radio-options {
      border: none;
      padding-right: 1rem;

      label {
        padding: 0;
      }
    }

    i::before {
        line-height: 70px;
    }

    .sun-rating-text {
      font-size: 0.8rem;
      color: #737373;
    }

    .hstack {
      border: none;
    }
  }

  textarea {
    border: none;

    & + .help-paragraph {
      border: none;
    }
  }
`

class HotelDetailsInput extends React.Component {
  render() {
    return (
      <StyledHotelDetailsInput>
        <Stack direction='horizontal'>
          <h3 className="category-label">
            <i className={this.props.icon} />
            {this.props.label}
          </h3>
          <div className="hoteldetails-suns">
            <SunRating
              name={this.props.name + '.rating'}
              formikProps={this.props.formikProps}
            />
          </div>
        </Stack>
        <Field
          label=""
          component={TextArea}
          name={this.props.name + '.description'}
          rows="4"
          help={this.props.help}
        />
      </StyledHotelDetailsInput>
    );
  }
}

const initialButtonState = {
  buttonState:
    [
      { id: 'toggle-cat-location', show: false },
      { id: 'toggle-cat-bed', show: false },
      { id: 'toggle-cat-service', show: false },
      { id: 'toggle-cat-food', show: false },
      { id: 'toggle-cat-leisure', show: false }
    ]
};

class HotelDetails extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = initialButtonState;
  }

  handleClick(buttonId) {
    this.setState(state => {
      const newState =
        state.buttonState.map((item) => {
          if (item.id === buttonId) {
            let show = !item.show;
            return { ...item, show };
          } else {
            return item;
          }
        })
      return { buttonState: newState }
    }
    );
  }

  render() {
    const buttonState = this.state.buttonState;
    const showLocation = buttonState.find(({ id }) => id === "toggle-cat-location").show;
    const showRooms = buttonState.find(({ id }) => id === "toggle-cat-bed").show;
    const showService = buttonState.find(({ id }) => id === "toggle-cat-service").show;
    const showFood = buttonState.find(({ id }) => id === "toggle-cat-food").show;
    const showLeisure = buttonState.find(({ id }) => id === "toggle-cat-leisure").show;

    return (
      <div className="hoteldetails-wrapper review-section">
        <h3>{this.props.t('review.hoteldetailstitle')}</h3>
        <p>{this.props.t('review.hoteldetailsdescription')}</p>

        <div className="hoteldetails-categories">
          <HotelDetailsCategories
            onClick={this.handleClick}
          />
        </div>

        <div className="hoteldetails-rating review-section">

          {showLocation ?
            <HotelDetailsInput
              label={this.props.t('review.hoteldetailscatlocation')}
              name="valuations.LOCATION"
              icon="icon icon-location"
              help={this.props.t('review.hoteldetailslocationhelp')}
              formikProps={this.props.formikProps}
            />
            : null}

          {showRooms ?
            <HotelDetailsInput
              label={this.props.t('review.hoteldetailscatrooms')}
              name="valuations.ROOM"
              icon="icon icon-bed"
              help={this.props.t('review.hoteldetailsroomshelp')}
              formikProps={this.props.formikProps}
            />
            : null}

          {showService ?
            <HotelDetailsInput
              label={this.props.t('review.hoteldetailscatservice')}
              name="valuations.SERVICE"
              icon="icon icon-service"
              help={this.props.t('review.hoteldetailsservicehelp')}
              formikProps={this.props.formikProps}
            />
            : null}

          {showFood ?
            <HotelDetailsInput
              label={this.props.t('review.hoteldetailscatfood')}
              name="valuations.GASTRONOMY"
              icon="icon icon-restaurant"
              help={this.props.t('review.hoteldetailsfoodhelp')}
              formikProps={this.props.formikProps}
            />
            : null}

          {showLeisure ?
            <HotelDetailsInput
              label={this.props.t('review.hoteldetailscatleisure')}
              name="valuations.SPORT"
              icon="icon icon-recreation"
              help={this.props.t('review.hoteldetailsleisurehelp')}
              formikProps={this.props.formikProps}
            />
            : null}
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(HotelDetails);