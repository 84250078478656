import React from "react";
import { APIHotelContextProvider } from "./common/ApiConnector"

import { AtmForm } from "./atm/AtmForm";

import Container from 'react-bootstrap/Container'

export default function Atm() {
  return (
    <Container fluid="md">
      <section>
        <APIHotelContextProvider>
          <AtmForm />
        </APIHotelContextProvider>
      </section>
    </Container>
  );
}
