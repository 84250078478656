import React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

import {APITracker, useAPIReview} from "../common/ApiConnector";
import { MapTraveledWith, GetLocaleString } from "../common/Mappings"
import StyledMainButton from "../common/StyledMainButton";
import { PaginatedReviews } from "./PaginatedReviews";

const StyledReviewsHeader = styled.div`
  text-align: center;

  h3 {
    padding: 4rem 0 3rem 0;
    font-family: 'Default', sans-serif;
  }
  .yellow-main-button {
    justify-content: flex-start;
  }

  .show-all-reviews {
    margin-top: 3rem;
  }
`

export function ViewReviews() {
  const { ReviewInfo, TotalReviews } = useAPIReview();
  const { t } = useTranslation('common');

  //console.log('total reviews:', TotalReviews);
  //console.log('reviews:', ReviewInfo);

  const formattedTotals = TotalReviews.toLocaleString(GetLocaleString());

  const showAllReviews = window.drupalSettings.hcpw.show_all_reviews === "1" ? true : null;

  let hcUrl = "https://www.holidaycheck.de/hi/";
  if (showAllReviews) {
    // Get hotel name from drupalSettings and format it accordingly to create the hcUrl.
    let hotelName = window.drupalSettings.hcpw.hotel_name;
    hotelName = hotelName.toLowerCase();
    hotelName = hotelName.replace(/ /g, '-');
    hcUrl = hcUrl + hotelName + '/' + window.drupalSettings.hcpw.hotel_id;
  }

  const handleAllReviewsClick = event => {
    //console.log('Anchor element clicked');
    // Refers to the link element:
    //console.log(event.currentTarget);
    APITracker('ALLREVIEWSCLICK');
  };

  let itemsPerPage = Number(window.drupalSettings.hcpw.pagination_reviews);
  if (!itemsPerPage) {
    itemsPerPage = ReviewInfo.length;
  }

  // @see #24716184 traveledWith is no longer mandatory
  // => insert placeholder when traveledWith is not set.
  // See also function MapTraveledWith() in Mappings.js.
  ReviewInfo.forEach((Rev) => {
    if (Rev.traveledWith === undefined) {
      Rev.traveledWith = 'NOTSPECIFIED';
    }
  });

  const [filteredReviews, setFilteredReviews] = useState(ReviewInfo);
  // Problem: pagination offset must be reset, when the select filter value changes
  // => remember resetOffset and old filterValue for pagination
  const [resetOffset, setResetOffset] = useState(false);
  const [filterValue, setFilterValue] = useState('any');

  const filterByTraveledWith = traveledWithValue => {

    if (filterValue !== traveledWithValue) {
      // Remember new filter setting.
      setFilterValue(traveledWithValue);
      // Pagination offset must be reset.
      setResetOffset(true);
    }

    if (traveledWithValue === 'any') {
      setFilteredReviews(ReviewInfo);
    } else {
      setFilteredReviews(
        ReviewInfo.filter(review => {
          return review.traveledWith === traveledWithValue
        })
      )
    }
  }
  const traveledWithOptions = Array.from(
    new Set(ReviewInfo.map(review => review.traveledWith))
  )

  // Callback function to un-reset the information,
  // that the item offset must be reset => called by PaginatedReviews.
  const resetCallback = () => {
    // Only change the state, if not already done to avoid multiple re-renderings.
    if (resetOffset === true) {
      setResetOffset(false);
    }
  };

  return (
    <StyledReviewsHeader id="reviews">
      <h3 className="yellow-underline">
        {t('viewreviews.title')}
      </h3>
      <p>{t('viewreviews.info', { totals: formattedTotals, maxreviewcount: window.drupalSettings.hcpw.max_review_count })}:</p>
      <div className='filter'>
        <span className="review-filter-label">{t('viewreviews.traveledas')}:</span>
        <select onChange={event => filterByTraveledWith(event.target.value)}
                name='review-filter-select'
        >
          <option
            key="0"
            value='any'
          >
            {t('viewreviews.any')}
          </option>

          {traveledWithOptions.map(traveledwith => {
            return <option
              key={traveledwith}
              value={traveledwith}
            >
              {MapTraveledWith(traveledwith)}
            </option>
          })}
        </select>
      </div>

      <div className='hcpw-reviews'>
        <PaginatedReviews
          Reviews={filteredReviews}
          itemsPerPage={itemsPerPage}
          resetOffset={resetOffset}
          resetCallback={resetCallback}
        />
      </div>
      {showAllReviews ?
        <StyledMainButton className="show-all-reviews">
          <a
            href={hcUrl}
            target="_blank"
            rel="noreferrer"
            className="hc-link"
            onClick={handleAllReviewsClick}
          >
            {t('viewreviews.readallreviews')}
          </a>
        </StyledMainButton>
        : null}
    </StyledReviewsHeader>
  );
}
