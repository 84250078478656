import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "./Select";
import RadioButtons from "./RadioButtons";
import { GetLocaleString } from "../../common/Mappings"
import TextError from "./TextError";
import { ErrorMessage } from "formik";
import styled from "styled-components";

const StyledTravelDate = styled.div`
  .radio-option {
    flex-basis: unset !important;
    flex-grow: 1;
  }
  .radio-option:last-of-type {
    width: 22%;
  }
`

export default function TravelDate({ formikProps }) {
  const { t } = useTranslation('common');
  const [showDateSelect, setShowDateSelect] = useState(false);

  const locale = GetLocaleString();
  const currentDate = new Date();
  let currentMonth = currentDate.getMonth() + 1;
  let currentYear = currentDate.getFullYear();

  let dateRadioOptions = [];
  let dateSelectOptions = [
    { key: t('review.datechoose'), value: '' },
  ];

  let i = 0;
  while (i < 24) {
    let date = new Date(Date.UTC(currentYear, currentMonth - 1, 1, 0, 0, 0))
    if (i < 6) {
      let name = date.toLocaleString(locale, { month: "short" });
      let value = currentMonth + '-' + currentYear;
      dateRadioOptions.push(
        { key: name, value: value, displayLabel: true }
      )
    }
    else {
      let name = date.toLocaleString(locale, { month: "long" }) + ' ' + currentYear;
      let value = currentMonth + '-' + currentYear;
      dateSelectOptions.push(
        { key: name, value: value }
      )
    }

    currentMonth--;
    if (currentMonth === 0) {
      currentMonth = 12;
      currentYear--;
    }

    i++;
  }

  dateRadioOptions.push(
    { key: t('review.datemorethansixmonths'), value: 'MORE_THAN_SIX_MONTHS', displayLabel: true }
  )

  const handleRadioButtons = event => {
    formikProps.handleChange(event);
    if (event.target.value === 'MORE_THAN_SIX_MONTHS') {
      setShowDateSelect(true);
    }
    else {
      setShowDateSelect(false);
    }

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <StyledTravelDate className="travelduration-wrapper review-section">
      <h3>{t('review.datetitle')} *</h3>
      <RadioButtons
        label=''
        buttonsperrow={7}
        name='travelDate'
        options={dateRadioOptions}
        onChange={
          event => { handleRadioButtons(event); }
        }
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.travelDate ? ' validation-error' : ''}
      />
      <ErrorMessage name="travelDate" component={TextError} />
      {showDateSelect ?
        <Select
          control="select"
          label=""
          name='dateSelectOption'
          options={dateSelectOptions}
          hasErrorClass={formikProps.errors.dateSelectOption ? ' validation-error' : ''}
        />
        : null}
    </StyledTravelDate>
  )
}
