import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css'

import { MapTravelDate } from "../common/Mappings";
import styled from "styled-components";

const HC_MEDIA_HOTEL_IMAGE_SMALL = 'https://media-cdn.holidaycheck.com/w_310,h_280,c_fill,q_auto,f_auto/ugc/images/';
//const HC_MEDIA_HOTEL_IMAGE_MEDIUM = 'https://media-cdn.holidaycheck.com/w_640,h_480,c_fill,q_80/ugc/images/';
//const HC_MEDIA_HOTEL_IMAGE_LARGE = 'https://media-cdn.holidaycheck.com/w_768,h_432,c_fill,q_80/ugc/images/';
//const HC_MEDIA_HOTEL_IMAGE_XLARGE = 'https://media-cdn.holidaycheck.com/w_1280,h_720,c_fill,q_auto,f_auto/ugc/images/';
const HC_MEDIA_HOTEL_IMAGE_XLARGE = 'https://media-cdn.holidaycheck.com/c_fit,f_webp,h_1920,q_auto,w_1080//ugc/images/';
const HC_MEDIA_HOTEL_IMAGE_XXLARGE = 'https://media-cdn.holidaycheck.com/w_2880,h_1620,c_fit,q_auto,f_auto/ugc/images/';

const StyledThumbnailWrapper = styled.div`
  overflow: hidden;
  position: relative;
  &:hover {
    .image-overlay {
      opacity: 0.7;
      bottom: 0px !important;
    }
  }
  .image-overlay {
    opacity: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    padding: 2px 10px;
    color: #ffffff;
    background: #000000;
    text-decoration: none;
    text-align: center;
    transition: opacity 750ms, bottom 500ms;
  }
  img {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
`
const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  justify-content: center;
`

export function ImageGallery({ images }) {
  const { t } = useTranslation('common');

  const galleryOptions = {
    showHideAnimationType: 'none',
    showAnimationDuration: 0,
    hideAnimationDuration: 0,
    /*
      // disable zoom on touch devices (?):
      zoomEl: false,
      maxSpreadZoom: 1,
      getDoubleTapZoom: function (isMouseClick, item) {
        return item.initialZoomLevel;
      },
      pinchToClose: false,
    */
    // UI options, hide zoom icon and counter
    zoom: false,
    counter: false,
    bgOpacity: 0.9,
    padding: { top: 20, bottom: 20, left: 70, right: 70 },
  }

  const ImageGallery = () => {

    useEffect(() => {
      document.querySelectorAll('.image-overlay').forEach(overlay => {
        overlay.style.bottom = -overlay.getBoundingClientRect().height + "px"
      })
    }, [])

    return (
      <Gallery
        options={galleryOptions}
        plugins={(pswpLightbox) => {
          // eslint-disable-next-line
          const captionPlugin = new PhotoSwipeDynamicCaption(pswpLightbox, {
            captionContent: (slide) =>
              (slide.data.description ? slide.data.description + '&nbsp;' : '') +
              t('viewimages.from') + '&nbsp;' + slide.data.username + ',&nbsp;' +
              slide.data.traveldate
          })
        }}
      >
        <StyledGrid>
          {images.map(image =>
            <div key={image.id}>
              <Item
                thumbnail={HC_MEDIA_HOTEL_IMAGE_SMALL + image.id}
                original={HC_MEDIA_HOTEL_IMAGE_XLARGE + image.id}
                originalSrcset={
                  HC_MEDIA_HOTEL_IMAGE_XLARGE + image.id + " 1x," +
                  HC_MEDIA_HOTEL_IMAGE_XXLARGE + image.id + " 2x," +
                  HC_MEDIA_HOTEL_IMAGE_XLARGE + image.id
                }
                width="0"
                height="0"
                alt={image.title}
                description={image.description}
                username={image.user.firstName}
                traveldate={MapTravelDate(image.travelDate)}
              >
                {({ ref, open }) => (
                  <StyledThumbnailWrapper className="thumbnail-wrapper">
                    <img
                      ref={ref}
                      onClick={open}
                      src={HC_MEDIA_HOTEL_IMAGE_SMALL + image.id}
                      alt={image.title}
                    />
                    <div className="image-overlay">
                      <div className="title">{image.description}</div>
                      <div className="name-date">{image.user.firstName}, {MapTravelDate(image.travelDate)}</div>
                    </div>
                  </StyledThumbnailWrapper>
                )}
              </Item>
            </div>
          )}
        </StyledGrid>
      </Gallery>
    )
  }

  return (
    <ImageGallery />
  );
}
