import React from "react";
import { useState } from "react";
// @see https://github.com/AdeleD/react-paginate
import ReactPaginate from 'react-paginate';
import SingleReview from './SingleReview'
import styled from "styled-components";

const StyledPaginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  padding: 0;
  margin: auto;
  margin-top: 2.5rem;
  .previous, .next {
    display: none;
  }
  li {
    list-style-type: none;
    z-index: 3;
    color: #333;
    background-color: #fff;
    box-shadow: 1px 0 0 0 #c2c7cc, 0 1px 0 0 #c2c7cc, 1px 1px 0 0 #c2c7cc, inset 1px 0 0 0 #c2c7cc, inset 0 1px 0 0 #c2c7cc;
    font-family: "Open Sans",sans-serif;
    font-size: 1.143rem;
    font-weight: 600;
    outline: 0;
    position: relative;
    text-decoration: none;

    &:not(.selected):hover {
      box-shadow: 1px 0 0 0 #51a9e0, 0 1px 0 0 #51a9e0, 1px 1px 0 0 #51a9e0, inset 1px 0 0 0 #51a9e0, inset 0 1px 0 0 #51a9e0;
      z-index: 5;
      color: #51a9e0;
    }

    &.selected {
      background-color: #fae896;
      box-shadow: 1px 0 0 0 #d9bd43, 0 1px 0 0 #d9bd43, 1px 1px 0 0 #d9bd43, inset 1px 0 0 0 #d9bd43, inset 0 1px 0 0 #d9bd43;
    }

    a {
      display: block;
      padding: 10px 17px;
      color: inherit;
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`

function RenderReviews({ currentReviews }) {
  return (
    <>
      {currentReviews.map(review =>
        <SingleReview key={review.id} review={review} />
      )}
    </>
  );
}

export function PaginatedReviews({ Reviews, itemsPerPage, resetOffset, resetCallback }) {
  const [itemOffset, setItemOffset] = useState(0);

  if (resetOffset === true) {
    // Inform the caller: we have reset the item offset.
    resetCallback();
    // Only change the item offset state, if not already done
    // to avoid multiple re-renderings and infinite-loops.
    if (itemOffset !== 0) {
      setItemOffset(0);
    }
  }

  const endOffset = itemOffset + itemsPerPage;
  //console.log(`Loading reviews from ${itemOffset} to ${endOffset}`);
  const currentReviews = Reviews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(Reviews.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % Reviews.length;
    //console.log(`Requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    document.getElementById('reviews').scrollIntoView()
  };

  return (
    <>
      <RenderReviews
        currentReviews={currentReviews}
      />
      {pageCount > 1 &&
        <StyledPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
        />}

    </>
  );
}
