import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import RadioButtons from "./RadioButtons";
import styled from "styled-components";

const StyledCostPerformance = styled.div`
  .radio-options {
    display: flex;
  }
  
  .radio-option {
    list-style-type: none;
    flex-basis: 20%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: white;

    &.active label {
      background-color: #fae896;
    }

    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .radio-option {
      i {
        margin: 0;
      }
      span {
      display: none;
    }
    } 
  }
`

export default function CostPerformance({ formikProps }) {
  const { t } = useTranslation('common');

  const handleRadioButtons = event => {
    formikProps.handleChange(event);

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <StyledCostPerformance className="costperformance-wrapper review-section">
      <h3>{t('review.costperformancetitle')} *</h3>
      <RadioButtons
        label=""
        name="costPerformance"
        options={[
          { key: t("review.costperfterrible"), value: 'TERRIBLE', iconClass: "icon icon-smiley-very-sad", displayLabel: true, displayIcon: true },
          { key: t("review.costperfbad"), value: 'BAD', iconClass: "icon icon-smiley-sad", displayLabel: true, displayIcon: true },
          { key: t("review.costperfacceptable"), value: 'ACCEPTABLE', iconClass: "icon icon-smiley-fine", displayLabel: true, displayIcon: true },
          { key: t("review.costperfgood"), value: 'GOOD', iconClass: "icon icon-smiley-good", displayLabel: true, displayIcon: true },
          { key: t("review.costperfexcellent"), value: 'EXCELLENT', iconClass: "icon icon-smiley-very-good", displayLabel: true, displayIcon: true }
        ]}
        onChange={event => {
          handleRadioButtons(event);
        }}
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.costPerformance ? ' validation-error' : ''}
      />
      <ErrorMessage name="costPerformance" component={TextError} />
    </StyledCostPerformance>
  )
}
