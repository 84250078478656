import React from "react";
import { useTranslation } from 'react-i18next';

import {APITracker, useAPIHotel} from "../common/ApiConnector";
import useRunOnce from "../common/useRunOnce";

import { Recommendation } from "./Recommendation"
import { Award } from "./ViewHeaderAward"
import { OverallRating } from "./OverallRating"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import { FakeSuspicion } from "./FakeSuspicion";
//import StyledMainButton from "../common/StyledMainButton";

import LogoRow from "../common/LogoRow";

import HotelInfoStack from "../common/StyledHotelInfoStack";


export function ViewHeader() {
  const { HotelInfo } = useAPIHotel();
  const { t } = useTranslation('common');

  // console.log('HotelInfo:', HotelInfo);
  // HotelInfo.profilepicture = "https://media-cdn.holidaycheck.com/w_320,h_240,c_fit,q_auto,f_auto/ugc/images/7403a082-8388-3a03-a029-16f1cd86e005"

  const filteredCity = HotelInfo.parents.filter(destination =>
    destination.destinationType === 'CITY'
  )[0].name;

  const filteredCountry = HotelInfo.parents.filter(destination =>
    destination.destinationType === 'COUNTRY'
  )[0].name;

  // This is currently not used, @see https://tojio.teamwork.com/#tasks/19050180?c=10068323
  // (it's not entirely removed, because the button possibly will have a comeback)
  //const reviewUrl = '/review/' + window.drupalSettings.hcpw.title + '/' + window.drupalSettings.hcpw.hotel_id;
  //const submitReviews = window.drupalSettings.hcpw.submissions_allowed === "1" ? true : null;

  // Save data in drupalSettings to let other components
  // easily access the hotel name without loading the whole hotel info.
  window.drupalSettings.hcpw.hotel_name = HotelInfo.name;
  window.drupalSettings.hcpw.hotel_country = filteredCountry;
  if (HotelInfo.renovation) {
    window.drupalSettings.hcpw.renovation_date = HotelInfo.renovation.date;
    window.drupalSettings.hcpw.renovation_description = HotelInfo.renovation.description;
  }

  // Track view only once.
  useRunOnce({
    fn: () => {
      // console.log("Runs once on mount");
      APITracker('VIEW');
    }
  });

  return (
    <>
      <LogoRow />

      <FakeSuspicion
        fakeInfo={HotelInfo.fake}
      />

      <Row sm="12" style={{ padding: '0 10.5px' }}>
        <HotelInfoStack direction="horizontal">
          <img
            src={HotelInfo.profilepicture}
            alt={HotelInfo.name}
            // Fallback placeholder on error, if there is no profile picture.
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // Prevent looping.
              currentTarget.src = "https://www.holidaycheck.de/public/assets/img/hotel-index-no-picture-placeholder.svg";
            }}
          />
          <Col>
            <Stack>
              <Stack direction="horizontal">
                <Award
                  awardInfo={HotelInfo.latestAward}
                />
                <div>
                  <h2>
                    {HotelInfo.name}
                  </h2>
                  <p className="location">
                    <i className="icon icon-location"></i>
                    {t('viewheader.city')} {filteredCity}
                  </p>
                </div>
              </Stack>
              <Recommendation
                Recommendation={HotelInfo.reviewCalculations.overall.recommendation}
                Rating={HotelInfo.reviewCalculations.overall.rating}
                percentage={true}
              />
            </Stack>
          </Col>
        </HotelInfoStack>

        <OverallRating
          Ratings={HotelInfo.reviewCalculations.perAspectGroup}
        />
      </Row>
      {/*
      {
        submitReviews ?
          <StyledMainButton className="yellow-main-button">
            <a
              className="pull-right"
              href={reviewUrl}
              target="_blank"
              rel="noreferrer"
            >
              {t('viewheader.reviewhotel')}
            </a>
          </StyledMainButton>
          : null
      }
      */}
    </>
  );
}
