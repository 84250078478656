import React from "react";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import TextError from "./TextError";

export default function Email({ formikProps }) {
  const { t } = useTranslation('common');

  return (
    <div className="email-wrapper review-section">
      <h3>
        {t('review.email')} *
      </h3>
      <Field
        type="email"
        name='anonymousUserInfo.emailAddress'
        size="60"
        maxLength="128"
        className={formikProps.errors.anonymousUserInfo ? ' validation-error' : ''}
      />
      <ErrorMessage name="anonymousUserInfo.emailAddress" component={TextError} />
    </div>
  );
}
