import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { ReviewHeader } from "./review/ReviewHeader";
import { APIHotelContextProvider } from "./common/ApiConnector";

import HotelGeneral from "./review/HotelGeneral";
import HotelDetails from "./review/HotelDetails";
import CostPerformance from "./review/elements/CostPerformance";
import ReviewTitle from "./review/elements/ReviewTitle";
import SubHeader from "./review/elements/SubHeader";
import SubFooter from "./review/elements/SubFooter";
import FirstName from "./review/elements/FirstName";
import Age from "./review/elements/Age";
import TraveledWith from "./review/elements/TraveledWith";
import TravelChildren from "./review/elements/TravelChildren";
import TravelDuration from "./review/elements/TravelDuration";
import TravelDate from "./review/elements/TravelDate";
import UserCountryCity from "./review/elements/UserCountryCity";
import Email from "./review/elements/Email";
import TandC from "./review/elements/TandC";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import TravelReason from "./review/elements/TravelReason";
import StyledMainButton from "./common/StyledMainButton";

import Container from "react-bootstrap/esm/Container";
import { ViewFooter } from "./common/ViewFooter";

import EmailConfirmationModal from './review/EmailConfirmationModal'

// Endpoint used to post review.
const API_POST_REVIEW = 'https://api.hcpw.vahaha.han-solo.net/svc/review-backend/hotelreview'

const initialValues = {
  title: '',
  travelDate: null,
  dateSelectOption: '',
  recommendation: null,
  costPerformance: null,
  ageGroup: null,
  firstName: '',
  userCountry: null,
  countrySelectOption: '',
  userCity: '',
  valuations: {
    GENERAL: {
      description: '',
      rating: 0
    },
    LOCATION: {
      description: '',
      rating: 0
    },
    ROOM: {
      description: '',
      rating: 0
    },
    SERVICE: {
      description: '',
      rating: 0
    },
    GASTRONOMY: {
      description: '',
      rating: 0
    },
    SPORT: {
      description: '',
      rating: 0
    }
  },
  travelDuration: null,
  durationSelectOption: '',
  traveledWith: null,
  travelReason: null,
  children: null,
  anonymousUserInfo: {
    emailAddress: ''
  }
}

export const createValidationSchema = () => {
  return Yup.object().shape({
    recommendation: Yup.string().required(i18n.t('common:review.hotelgeneralrecommendationrequired')),
    valuations: Yup.object({
      GENERAL: Yup.object({
        rating: Yup
          .number()
          .min(1, i18n.t('common:review.hotelgeneralratingrequired'))
          .required(i18n.t('common:review.hotelgeneralratingrequired')),
        description: Yup
          .string()
          .min(100, i18n.t('common:review.hotelgeneraldescriptionrequired'))
          .required(i18n.t('common:review.hotelgeneraldescriptionrequired'))
      }),
      LOCATION: Yup.object({
        description: Yup.string(),
        rating: Yup
          .number()
          // For validation of nested objects with context:
          // @see https://github.com/jquense/yup/issues/735#issuecomment-1094484630
          .test(
            'location-rating-conditionally-required',
            i18n.t('common:review.sunratingrequired'),
            (value, context) => {
              const locationdescription = context.from[0].value.description;
              // Validation: if there is a text in description, the sun-rating must be filled.
              return (value > 0 || (locationdescription === '' || locationdescription === undefined));
            }
          )
      }),
      ROOM: Yup.object({
        description: Yup.string(),
        rating: Yup
          .number()
          .test(
            'room-rating-conditionally-required',
            i18n.t('common:review.sunratingrequired'),
            (value, context) => {
              const roomdescription = context.from[0].value.description;
              return (value > 0 || (roomdescription === '' || roomdescription === undefined));
            }
          )
      }),
      SERVICE: Yup.object({
        description: Yup.string(),
        rating: Yup
          .number()
          .test(
            'service-rating-conditionally-required',
            i18n.t('common:review.sunratingrequired'),
            (value, context) => {
              const servicedescription = context.from[0].value.description;
              return (value > 0 || (servicedescription === '' || servicedescription === undefined));
            }
          )
      }),
      GASTRONOMY: Yup.object({
        description: Yup.string(),
        rating: Yup
          .number()
          .test(
            'gastronomy-rating-conditionally-required',
            i18n.t('common:review.sunratingrequired'),
            (value, context) => {
              const gastrodescription = context.from[0].value.description;
              return (value > 0 || (gastrodescription === '' || gastrodescription === undefined));
            }
          )
      }),
      SPORT: Yup.object({
        description: Yup.string(),
        rating: Yup
          .number()
          .test(
            'sport-rating-conditionally-required',
            i18n.t('common:review.sunratingrequired'),
            (value, context) => {
              const sportdescription = context.from[0].value.description;
              return (value > 0 || (sportdescription === '' || sportdescription === undefined));
            }
          )
      }),
    }),
    costPerformance: Yup.string().required(i18n.t('common:review.costperformancerequired')),
    title: Yup
      .string()
      .min(2, i18n.t('common:review.titlerequired'))
      .max(50, i18n.t('common:review.titlerequired'))
      .required(i18n.t('common:review.titlerequired')),
    firstName: Yup.string().required(i18n.t('common:review.firstnamerequired')),
    ageGroup: Yup.string().required(i18n.t('common:review.agerequired')),
    traveledWith: Yup.string().required(i18n.t('common:review.traveledwithrequired')),
    children: Yup.string().required(i18n.t('common:review.childrenrequired')),
    travelReason: Yup.string().required(i18n.t('common:review.reasonrequired')),
    travelDuration: Yup.string().required(i18n.t('common:review.durationrequired')),
    durationSelectOption: Yup
      .string()
      .when('travelDuration', {
        is: (travelDuration) => travelDuration === 'MORE_THAN_TWO_WEEKS',
        then: (durationSelectOption) => Yup.string().required(i18n.t('common:review.durationrequired'))
      }),
    travelDate: Yup.string().required(i18n.t('common:review.daterequired')),
    dateSelectOption: Yup
      .string()
      .when('travelDate', {
        is: (travelDate) => travelDate === 'MORE_THAN_SIX_MONTHS',
        then: (dateSelectOption) => Yup.string().required(i18n.t('common:review.daterequired'))
      }),
    userCountry: Yup.string().required(i18n.t('common:review.countryrequired')),
    countrySelectOption: Yup
      .string()
      .when('userCountry', {
        is: (userCountry) => userCountry === 'ALL_OTHER_COUNTRIES',
        then: (countrySelectOption) => Yup.string().required(i18n.t('common:review.countryrequired'))
      }),
    userCity: Yup.string().required(i18n.t('common:review.cityrequired')),
    anonymousUserInfo: Yup.object({
      emailAddress: Yup
        .string()
        .email(i18n.t('common:review.emailinvalid'))
        .required(i18n.t('common:review.emailrequired'))
    }),
  })
};

// Store the validationSchema in a global variable
// because it has to be re-initialized when language is set.
// @see App.js (i18n.changeLanguage)
window.validationSchema = createValidationSchema();

export default function Review() {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation('common');

  const handleSubmission = () => {
    setTimeout(() => {
      let firstError = document.getElementsByClassName('validation-error')[0]
      if (firstError) {
        firstError.scrollIntoView({block: 'nearest'})
      }
      else {
        firstError = document.getElementsByClassName('text-error')[0]
        console.log('text firstError:', firstError)
        if (firstError) {
          firstError.scrollIntoView({block: 'center'})
        }
      }
    }, 100)
  }

  const onSubmit = (values, submitProps) => {

    // Correct submitted values for special fields.
    if (values.travelDuration === "MORE_THAN_TWO_WEEKS") {
      values.travelDuration = values.durationSelectOption;
    }
    // Remove durationSelectOption from values.
    delete values.durationSelectOption;

    if (values.travelDate === "MORE_THAN_SIX_MONTHS") {
      values.travelDate = values.dateSelectOption;
    }
    // Remove durationSelectOption from values.
    delete values.dateSelectOption;

    if (values.userCountry === "ALL_OTHER_COUNTRIES") {
      values.userCountry = values.countrySelectOption;
    }
    // Remove durationSelectOption from values.
    delete values.countrySelectOption;

    // Convert sun-ratings to normalized values.
    if (values.valuations.GASTRONOMY.rating !== 0) {
      values.valuations.GASTRONOMY.rating = Math.round(values.valuations.GASTRONOMY.rating * 100 / 6) / 100;
    }
    if (values.valuations.GENERAL.rating !== 0) {
      values.valuations.GENERAL.rating = Math.round(values.valuations.GENERAL.rating * 100 / 6) / 100;
    }
    if (values.valuations.LOCATION.rating !== 0) {
      values.valuations.LOCATION.rating = Math.round(values.valuations.LOCATION.rating * 100 / 6) / 100;
    }
    if (values.valuations.ROOM.rating !== 0) {
      values.valuations.ROOM.rating = Math.round(values.valuations.ROOM.rating * 100 / 6) / 100;
    }
    if (values.valuations.SERVICE.rating !== 0) {
      values.valuations.SERVICE.rating = Math.round(values.valuations.SERVICE.rating * 100 / 6) / 100;
    }
    if (values.valuations.SPORT.rating !== 0) {
      values.valuations.SPORT.rating = Math.round(values.valuations.SPORT.rating * 100 / 6) / 100;
    }

    // Get parent id from window.settings
    values.parentId = window.drupalSettings.hcpw.hotel_id;

    //console.log('Form data', values);
    //console.log('submitProps', submitProps);

    // Post values.
    const requestOptions = {
      method: 'POST',
      headers: {
        'Partner-ID': window.drupalSettings.hcpw.partner_id,
        'Content-Language': window.drupalSettings.path.currentLanguage,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    };
    fetch(API_POST_REVIEW, requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        // check for error response
        if (!response.ok) {
          // Get error message from body or default to response status.
          const error = (data && data.message) || response.status;
          console.error('POST Request: There was an error!', error);
          return Promise.reject(error);
        }
        console.log('POST request successful, result:', data);
        // Post successful, show modal window and redirect to "views" page of the hotel.
        setShowModal(prev => true)
      })
      .catch(error => {
        console.error('POST Request: There was an error!', error);
      })
      .finally(() => {
        submitProps.setSubmitting(false);
        submitProps.resetForm();
      })
  }

  return (
    <Container fluid="md">
      <section>
        <APIHotelContextProvider>
          <ReviewHeader />
        </APIHotelContextProvider>
      </section>
      <section>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={window.validationSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            // TODO: remove log.
            //console.log('formik props', formikProps)

            return (
              <Form>
                <div className="main-form">
                  <HotelGeneral
                    formikProps={formikProps}
                  />
                  <HotelDetails
                    formikProps={formikProps}
                  />
                  <CostPerformance
                    formikProps={formikProps}
                  />
                  <ReviewTitle
                    formikProps={formikProps}
                  />

                  <SubHeader />

                  <FirstName
                    formikProps={formikProps}
                  />
                  <Age
                    formikProps={formikProps}
                  />
                  <TraveledWith
                    formikProps={formikProps}
                  />
                  <TravelReason
                    formikProps={formikProps}
                  />
                  <TravelChildren
                    formikProps={formikProps}
                  />
                  <TravelDuration
                    formikProps={formikProps}
                  />
                  <TravelDate
                    formikProps={formikProps}
                  />
                  <UserCountryCity
                    formikProps={formikProps}
                  />

                  <SubFooter />

                  <Email
                    formikProps={formikProps}
                  />

                  <TandC />

                  <StyledMainButton className="submit-wrapper" style={{ justifyContent: 'center', marginTop: '3rem' }}>
                    <button
                      type='submit'
                      className=""
                      disabled={formikProps.isSubmitting}
                      onClick={handleSubmission}
                    >
                      {t('review.sendreview')}
                    </button>
                  </StyledMainButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </section>
      <ViewFooter />
      {showModal && <EmailConfirmationModal show={showModal} setShow={setShowModal} />}
    </Container>
  );
}