import React from 'react'
import styled from 'styled-components'

const StyledTextError = styled.div`
  color: #E8402D;
  padding-top: 0.2rem;
  font-size: 0.79rem;
  line-height: 1.14rem;
`

function TextError(props) {
  return <StyledTextError className='text-error'>{props.children}</StyledTextError>
}

export default TextError