import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components';

const StyledRadioButtonInRow = styled.div`
  flex-basis: calc(100% / ${props => props.buttonsperrow});
  max-width: calc(100% / ${props => props.buttonsperrow});
  @media (max-width: 991px) {
    width: unset !important;
}
`

const StyledRadioButtonGroup = styled.div`
  &.validation-error {

    .radio-option {
      border-top-color: red;
      border-bottom-color: red;

      &:first-of-type {
        border-left-color: red;

        &:hover {
          border-left-color: initial;
        }
      }
      &:last-of-type {
        border-right-color: red;

        &:hover {
          border-right-color: initial;
        }
      }

      &:hover {
        border-top-color: initial;
        border-bottom-color: initial;
      }
    }

    & + .select-control select {
      border-color: red;
    }
  }
`
export default function RadioButtons(props) {

  const onMouseEnter = event => {
    let targetRadioOptionDiv = event.target;
    if (event.target.tagName !== 'DIV') {
      targetRadioOptionDiv = event.target.closest('.radio-option');
    }

    let wrapperDiv = event.target.closest('.radio-options');
    let radioOptionDivs = wrapperDiv.querySelectorAll('.radio-option');

    radioOptionDivs.forEach((radioOptionDiv) => radioOptionDiv.classList.remove("sun-hover"));

    let divAddClassHover = true;
    radioOptionDivs.forEach((radioOptionDiv) => {
      if (divAddClassHover) {
        radioOptionDiv.classList.add("sun-hover");
      }
      if (radioOptionDiv === targetRadioOptionDiv) {
        divAddClassHover = false;
        var inputElement = radioOptionDiv.querySelectorAll("input");
        var element = document.getElementById(props.name + 'suntext');
        element.innerHTML = inputElement[0].getAttribute('data-selection-text');
      }
    });
  }

  let { label, name, options, iconClass, hasErrorClass, ...rest } = props
  if (!hasErrorClass) hasErrorClass = '';

  return (
    <StyledRadioButtonGroup
      className={'radio-options' + hasErrorClass}
    >
      {label ? <label>{label}</label> : null}
      <Field name={name}>
        {({ field }) => {
          return options.map(option => {
            return (
              <StyledRadioButtonInRow
                className="radio-option"
                key={option.key}
                buttonsperrow={props.buttonsperrow}
                onMouseEnter={option.mouseenter ? event => onMouseEnter(event) : null}
              >
                <input
                  type='radio'
                  id={option.key}
                  data-selection-text={option.dataSelectionText}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value === option.value}
                />
                {option.displayLabel && option.displayIcon ?
                  <label htmlFor={option.key}><span>{option.key}</span><i className={option.iconClass}></i></label>
                  :
                  option.displayLabel ?
                    <label htmlFor={option.key}><span>{option.key}</span></label>
                    :
                    <label htmlFor={option.key}><i className={option.iconClass}></i></label>
                }
              </StyledRadioButtonInRow>
            );
          });
        }}
      </Field>
    </StyledRadioButtonGroup>
  )
}