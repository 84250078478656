import React from "react";
import { useTranslation } from "react-i18next";
import FakeModal from "./FakeModal";
import styled from "styled-components";

const StyledFakeInfo = styled.div`
  margin: 1rem 0;
  .fake-info-red {
    color: #f03a47;
    font-weight: bold;
  }
  .fake-info {
    margin: 0;
  }
  .hotel-header-modal {
    margin-top: 0.5rem !important;
    all: inherit;
    color: ${props => props.theme.colors.linkblue};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export function FakeSuspicion({ fakeInfo }) {
  const { t } = useTranslation('common');

  if (fakeInfo && fakeInfo.category === 'FAKE_SUSPICION') {
    return (
      <StyledFakeInfo className="fake-info">
        <p className="fake-info-red">
          {t('viewheader.fakewarning')}
        </p>
        <p className="fake-info">
          {t('viewheader.fakeinfo')}
        </p>
        <FakeModal
          text={fakeInfo.explanation}
        />
      </StyledFakeInfo>
    );
  } else {
    // No fake.
    return null;
  }
}