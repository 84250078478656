import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "./Select";
import RadioButtons from "./RadioButtons";
import City from "./City";
import TextError from "./TextError";
import { ErrorMessage } from "formik";
import styled from "styled-components";

const StyledCountrySelect = styled.div`
  margin: 3rem 0;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    flex-basis: calc(50% - 15px);
  }

  .select-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .city-wrapper, .country-wrapper {
    flex-basis: calc(50% - 15px);
    width: calc(50% - 15px);

    @media (max-width: 767px) {
    flex-basis: 100%;
    width: 100%;

    &.city-wrapper {
      margin-top: 1.5rem;
    }
  }

    h3 {
      font-weight: 400 !important;
    }
  }
`

export default function UserCountryCity({ formikProps }) {
  const { t } = useTranslation('common');
  const [showCitySelect, setShowCitySelect] = useState(false);
  const [showCountrySelect, setShowCountrySelect] = useState(false);

  const countryRadioOptions = [
    { key: t('review.countryDE'), value: 'DE', displayLabel: true },
    { key: t('review.countryAT'), value: 'AT', displayLabel: true },
    { key: t('review.countryCH'), value: 'CH', displayLabel: true },
    { key: t('review.allothercountries'), value: 'ALL_OTHER_COUNTRIES', displayLabel: true },
  ];

  let countrySelectOptions = [
    { key: t('review.countryAB'), value: 'AB' },
    { key: t('review.countryAF'), value: 'AF' },
    { key: t('review.countryEG'), value: 'EG' },
    { key: t('review.countryAL'), value: 'AL' },
    { key: t('review.countryDZ'), value: 'DZ' },
    { key: t('review.countryAS'), value: 'AS' },
    { key: t('review.countryAD'), value: 'AD' },
    { key: t('review.countryAO'), value: 'AO' },
    { key: t('review.countryAI'), value: 'AI' },
    { key: t('review.countryAQ'), value: 'AQ' },
    { key: t('review.countryAG'), value: 'AG' },
    { key: t('review.countryGQ'), value: 'GQ' },
    { key: t('review.countryAR'), value: 'AR' },
    { key: t('review.countryXR'), value: 'XR' },
    { key: t('review.countryAM'), value: 'AM' },
    { key: t('review.countryAW'), value: 'AW' },
    { key: t('review.countryAZ'), value: 'AZ' },
    { key: t('review.countryET'), value: 'ET' },
    { key: t('review.countryAU'), value: 'AU' },
    { key: t('review.countryBS'), value: 'BS' },
    { key: t('review.countryBH'), value: 'BH' },
    { key: t('review.countryBD'), value: 'BD' },
    { key: t('review.countryBB'), value: 'BB' },
    { key: t('review.countryBE'), value: 'BE' },
    { key: t('review.countryBZ'), value: 'BZ' },
    { key: t('review.countryBJ'), value: 'BJ' },
    { key: t('review.countryBM'), value: 'BM' },
    { key: t('review.countryBT'), value: 'BT' },
    { key: t('review.countryBO'), value: 'BO' },
    { key: t('review.countryBA'), value: 'BA' },
    { key: t('review.countryBW'), value: 'BW' },
    { key: t('review.countryBR'), value: 'BR' },
    { key: t('review.countryVG'), value: 'VG' },
    { key: t('review.countryBN'), value: 'BN' },
    { key: t('review.countryBG'), value: 'BG' },
    { key: t('review.countryBF'), value: 'BF' },
    { key: t('review.countryBI'), value: 'BI' },
    { key: t('review.countryCL'), value: 'CL' },
    { key: t('review.countryCN'), value: 'CN' },
    { key: t('review.countryCK'), value: 'CK' },
    { key: t('review.countryCR'), value: 'CR' },
    { key: t('review.countryCW'), value: 'CW' },
    { key: t('review.countryDK'), value: 'DK' },
    { key: t('review.countryCD'), value: 'CD' },
    { key: t('review.countryDM'), value: 'DM' },
    { key: t('review.countryDO'), value: 'DO' },
    { key: t('review.countryDJ'), value: 'DJ' },
    { key: t('review.countryEC'), value: 'EC' },
    { key: t('review.countrySV'), value: 'SV' },
    { key: t('review.countryCI'), value: 'CI' },
    { key: t('review.countryER'), value: 'ER' },
    { key: t('review.countryEE'), value: 'EE' },
    { key: t('review.countryFK'), value: 'FK' },
    { key: t('review.countryFO'), value: 'FO' },
    { key: t('review.countryFJ'), value: 'FJ' },
    { key: t('review.countryFI'), value: 'FI' },
    { key: t('review.countryFR'), value: 'FR' },
    { key: t('review.countryGF'), value: 'GF' },
    { key: t('review.countryPF'), value: 'PF' },
    { key: t('review.countryGA'), value: 'GA' },
    { key: t('review.countryGM'), value: 'GM' },
    { key: t('review.countryGE'), value: 'GE' },
    { key: t('review.countryGH'), value: 'GH' },
    { key: t('review.countryGD'), value: 'GD' },
    { key: t('review.countryGR'), value: 'GR' },
    { key: t('review.countryGL'), value: 'GL' },
    { key: t('review.countryGB'), value: 'GB' },
    { key: t('review.countryGP'), value: 'GP' },
    { key: t('review.countryGU'), value: 'GU' },
    { key: t('review.countryGT'), value: 'GT' },
    { key: t('review.countryGN'), value: 'GN' },
    { key: t('review.countryGW'), value: 'GW' },
    { key: t('review.countryGY'), value: 'GY' },
    { key: t('review.countryHT'), value: 'HT' },
    { key: t('review.countryHN'), value: 'HN' },
    { key: t('review.countryHK'), value: 'HK' },
    { key: t('review.countryIN'), value: 'IN' },
    { key: t('review.countryID'), value: 'ID' },
    { key: t('review.countryIQ'), value: 'IQ' },
    { key: t('review.countryIR'), value: 'IR' },
    { key: t('review.countryIE'), value: 'IE' },
    { key: t('review.countryIS'), value: 'IS' },
    { key: t('review.countryIL'), value: 'IL' },
    { key: t('review.countryIT'), value: 'IT' },
    { key: t('review.countryJM'), value: 'JM' },
    { key: t('review.countryJP'), value: 'JP' },
    { key: t('review.countryYE'), value: 'YE' },
    { key: t('review.countryJO'), value: 'JO' },
    { key: t('review.countryKY'), value: 'KY' },
    { key: t('review.countryKH'), value: 'KH' },
    { key: t('review.countryCM'), value: 'CM' },
    { key: t('review.countryCA'), value: 'CA' },
    { key: t('review.countryCV'), value: 'CV' },
    { key: t('review.countryBQ'), value: 'BQ' },
    { key: t('review.countryKZ'), value: 'KZ' },
    { key: t('review.countryKE'), value: 'KE' },
    { key: t('review.countryKG'), value: 'KG' },
    { key: t('review.countryKI'), value: 'KI' },
    { key: t('review.countryCO'), value: 'CO' },
    { key: t('review.countryKM'), value: 'KM' },
    { key: t('review.countryXK'), value: 'XK' },
    { key: t('review.countryHR'), value: 'HR' },
    { key: t('review.countryCU'), value: 'CU' },
    { key: t('review.countryKW'), value: 'KW' },
    { key: t('review.countryLA'), value: 'LA' },
    { key: t('review.countryLS'), value: 'LS' },
    { key: t('review.countryLV'), value: 'LV' },
    { key: t('review.countryLB'), value: 'LB' },
    { key: t('review.countryLR'), value: 'LR' },
    { key: t('review.countryLY'), value: 'LY' },
    { key: t('review.countryLI'), value: 'LI' },
    { key: t('review.countryLT'), value: 'LT' },
    { key: t('review.countryLU'), value: 'LU' },
    { key: t('review.countryMO'), value: 'MO' },
    { key: t('review.countryMG'), value: 'MG' },
    { key: t('review.countryMW'), value: 'MW' },
    { key: t('review.countryMY'), value: 'MY' },
    { key: t('review.countryMV'), value: 'MV' },
    { key: t('review.countryML'), value: 'ML' },
    { key: t('review.countryMT'), value: 'MT' },
    { key: t('review.countryMA'), value: 'MA' },
    { key: t('review.countryMH'), value: 'MH' },
    { key: t('review.countryMQ'), value: 'MQ' },
    { key: t('review.countryMR'), value: 'MR' },
    { key: t('review.countryMU'), value: 'MU' },
    { key: t('review.countryYT'), value: 'YT' },
    { key: t('review.countryMK'), value: 'MK' },
    { key: t('review.countryMX'), value: 'MX' },
    { key: t('review.countryFM'), value: 'FM' },
    { key: t('review.countryMD'), value: 'MD' },
    { key: t('review.countryMC'), value: 'MC' },
    { key: t('review.countryMN'), value: 'MN' },
    { key: t('review.countryME'), value: 'ME' },
    { key: t('review.countryMS'), value: 'MS' },
    { key: t('review.countryMZ'), value: 'MZ' },
    { key: t('review.countryMM'), value: 'MM' },
    { key: t('review.countryNA'), value: 'NA' },
    { key: t('review.countryNR'), value: 'NR' },
    { key: t('review.countryNP'), value: 'NP' },
    { key: t('review.countryNC'), value: 'NC' },
    { key: t('review.countryNZ'), value: 'NZ' },
    { key: t('review.countryNI'), value: 'NI' },
    { key: t('review.countryNL'), value: 'NL' },
    { key: t('review.countryNE'), value: 'NE' },
    { key: t('review.countryNG'), value: 'NG' },
    { key: t('review.countryNU'), value: 'NU' },
    { key: t('review.countryKP'), value: 'KP' },
    { key: t('review.countryMP'), value: 'MP' },
    { key: t('review.countryNO'), value: 'NO' },
    { key: t('review.countryOM'), value: 'OM' },
    { key: t('review.countryTL'), value: 'TL' },
    { key: t('review.countryPK'), value: 'PK' },
    { key: t('review.countryPS'), value: 'PS' },
    { key: t('review.countryPW'), value: 'PW' },
    { key: t('review.countryPA'), value: 'PA' },
    { key: t('review.countryPG'), value: 'PG' },
    { key: t('review.countryPY'), value: 'PY' },
    { key: t('review.countryPE'), value: 'PE' },
    { key: t('review.countryPH'), value: 'PH' },
    { key: t('review.countryPN'), value: 'PN' },
    { key: t('review.countryPL'), value: 'PL' },
    { key: t('review.countryPT'), value: 'PT' },
    { key: t('review.countryPR'), value: 'PR' },
    { key: t('review.countryQA'), value: 'QA' },
    { key: t('review.countryCG'), value: 'CG' },
    { key: t('review.countryRE'), value: 'RE' },
    { key: t('review.countryRW'), value: 'RW' },
    { key: t('review.countryRO'), value: 'RO' },
    { key: t('review.countryRU'), value: 'RU' },
    { key: t('review.countryPM'), value: 'PM' },
    { key: t('review.countrySB'), value: 'SB' },
    { key: t('review.countryZM'), value: 'ZM' },
    { key: t('review.countryWS'), value: 'WS' },
    { key: t('review.countrySM'), value: 'SM' },
    { key: t('review.countryST'), value: 'ST' },
    { key: t('review.countrySA'), value: 'SA' },
    { key: t('review.countrySE'), value: 'SE' },
    { key: t('review.countrySN'), value: 'SN' },
    { key: t('review.countryRS'), value: 'RS' },
    { key: t('review.countrySC'), value: 'SC' },
    { key: t('review.countrySL'), value: 'SL' },
    { key: t('review.countryZW'), value: 'ZW' },
    { key: t('review.countrySG'), value: 'SG' },
    { key: t('review.countrySK'), value: 'SK' },
    { key: t('review.countrySI'), value: 'SI' },
    { key: t('review.countrySO'), value: 'SO' },
    { key: t('review.countryES'), value: 'ES' },
    { key: t('review.countryLK'), value: 'LK' },
    { key: t('review.countryBL'), value: 'BL' },
    { key: t('review.countryKN'), value: 'KN' },
    { key: t('review.countryLC'), value: 'LC' },
    { key: t('review.countryMF'), value: 'MF' },
    { key: t('review.countrySX'), value: 'SX' },
    { key: t('review.countryVC'), value: 'VC' },
    { key: t('review.countryZA'), value: 'ZA' },
    { key: t('review.countrySD'), value: 'SD' },
    { key: t('review.countryGS'), value: 'GS' },
    { key: t('review.countryKR'), value: 'KR' },
    { key: t('review.countrySS'), value: 'SS' },
    { key: t('review.countrySR'), value: 'SR' },
    { key: t('review.countrySZ'), value: 'SZ' },
    { key: t('review.countrySY'), value: 'SY' },
    { key: t('review.countryTJ'), value: 'TJ' },
    { key: t('review.countryTW'), value: 'TW' },
    { key: t('review.countryTZ'), value: 'TZ' },
    { key: t('review.countryTH'), value: 'TH' },
    { key: t('review.countryTG'), value: 'TG' },
    { key: t('review.countryTO'), value: 'TO' },
    { key: t('review.countryTT'), value: 'TT' },
    { key: t('review.countryTD'), value: 'TD' },
    { key: t('review.countryCZ'), value: 'CZ' },
    { key: t('review.countryTN'), value: 'TN' },
    { key: t('review.countryTR'), value: 'TR' },
    { key: t('review.countryTM'), value: 'TM' },
    { key: t('review.countryTC'), value: 'TC' },
    { key: t('review.countryTV'), value: 'TV' },
    { key: t('review.countryVI'), value: 'VI' },
    { key: t('review.countryUG'), value: 'UG' },
    { key: t('review.countryUA'), value: 'UA' },
    { key: t('review.countryHU'), value: 'HU' },
    { key: t('review.countryUY'), value: 'UY' },
    { key: t('review.countryUS'), value: 'US' },
    { key: t('review.countryUZ'), value: 'UZ' },
    { key: t('review.countryVU'), value: 'VU' },
    { key: t('review.countryVE'), value: 'VE' },
    { key: t('review.countryAE'), value: 'AE' },
    { key: t('review.countryVN'), value: 'VN' },
    { key: t('review.countryWF'), value: 'WF' },
    { key: t('review.countryBY'), value: 'BY' },
    { key: t('review.countryCF'), value: 'CF' },
    { key: t('review.countryCY'), value: 'CY' }
  ];

  // Compare with locale for sorting.
  function compare(a, b) {
    return a.key.localeCompare(b.key);
  }
  // Sort countries according to selected language.
  countrySelectOptions.sort(compare)
  // Insert "- please choose -" as first element.
  let countryChoose = { key: t('review.countrychoose'), value: '' };
  countrySelectOptions = [countryChoose].concat(countrySelectOptions);

  const handleRadioButtons = event => {
    formikProps.handleChange(event);
    setShowCitySelect(true);
    if (event.target.value === 'ALL_OTHER_COUNTRIES') {
      setShowCountrySelect(true);
    }
    else {
      setShowCountrySelect(false);
    }

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <StyledCountrySelect className="usercountry-wrapper review-section">
      <h3>{t('review.countrytitle')} *</h3>
      <RadioButtons
        label=''
        buttonsperrow={4}
        name='userCountry'
        options={countryRadioOptions}
        onChange={
          event => { handleRadioButtons(event); }
        }
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.userCountry ? ' validation-error' : ''}
      />
      <ErrorMessage name="userCountry" component={TextError} />
      <div className="select-wrapper">
        {showCountrySelect ?
          <div className="country-wrapper">
            <h3>{t('review.countrytitle')}</h3>
            <Select
              control="select"
              label=""
              name='countrySelectOption'
              options={countrySelectOptions}
              hasErrorClass={formikProps.errors.countrySelectOption ? ' validation-error' : ''}
            />
          </div>
          : null}
        {showCitySelect ?
          <City
            formikProps={formikProps}
          />
          : null}
      </div>
    </StyledCountrySelect>
  )
}
