import React from "react";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import { RecommendationSuns } from "./RecommendationSuns"

const StyledOverallRating = styled.div`
  flex-wrap: wrap;
  column-count: 3;
  column-rule: 1px solid ${props => props.theme.colors.gray};
  border: 1px solid ${props => props.theme.colors.gray};
  padding: 7px;
  margin-top: 2rem;
  margin-bottom: 2rem;


  &> div {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
  }

  @media (max-width: 991px) {
    column-count: 2;
  }
  @media (max-width: 767px) {
    column-count: 1;
  }
`

export function OverallRating({ Ratings }) {
  const { t } = useTranslation('common');
  const noRating = Object.values(Ratings).every(item => item.rating === null);
  if (noRating === false) {
    return (
      <StyledOverallRating direction='horizontal' className='hotel-info-overall-rating'>
        <div>{t('viewreviews.hotel')}
          <RecommendationSuns
            Rating={Ratings.HOTEL.rating}
            showMaxRating={false}
          />
        </div>
        <div>{t('viewreviews.room')}
          <RecommendationSuns
            Rating={Ratings.ROOM.rating}
            showMaxRating={false}
          />
        </div>
        <div>{t('viewreviews.service')}
          <RecommendationSuns
            Rating={Ratings.SERVICE.rating}
            showMaxRating={false}
          />
        </div>
        <div>{t('viewreviews.location')}
          <RecommendationSuns
            Rating={Ratings.LOCATION.rating}
            showMaxRating={false}
          />
        </div>
        <div>{t('viewreviews.food')}
          <RecommendationSuns
            Rating={Ratings.FOOD.rating}
            showMaxRating={false}
          />
        </div>
        <div>{t('viewreviews.sport')}
          <RecommendationSuns
            Rating={Ratings.SPORT.rating}
            showMaxRating={false}
          />
        </div>
      </StyledOverallRating>
    );
  }
}