import styled from "styled-components"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import {APITracker} from "./ApiConnector";

const StyledRow = styled(Row)`
  margin-top: 1.5rem;
  align-items: center;

  .header-logo, .hc-logo {
    img {
      max-width: 200px;
    }
  }
`

const StyledLogoRow = () => {

  const logoUrl = window.drupalSettings.hcpw.logo ? window.drupalSettings.hcpw.href + window.drupalSettings.hcpw.logo : null;
  const partnerLink = window.drupalSettings.hcpw.partner_link;
  const hcLogoUrl = window.drupalSettings.hcpw.href + '/themes/custom/hcpw_base/img/logo/svg/160218_HC_Logo_16_bw_RGB.svg';
  const holidaycheckLink = window.drupalSettings.hcpw.holidaycheck_link;

  const handleHcLogoClick = event => {
    //console.log('Anchor element clicked');
    // Refers to the link element:
    //console.log(event.currentTarget);
    // Log view clicks only.
    if (window.drupalSettings.hcpw.action === 'view') {
      APITracker('HCLOGOCLICK');
    }
  };

  return (
    <StyledRow>
      <Col xs='6' sm='3'>
        {logoUrl && partnerLink ?
          <div className='header-logo'>
            <a href={partnerLink} target="_blank" rel="noreferrer">
              <img
                src={logoUrl}
                alt={window.drupalSettings.hcpw.title}
              />
            </a>
          </div>
          : logoUrl ?
            <div className='header-logo'>
              <img
                src={logoUrl}
                alt={window.drupalSettings.hcpw.title}
              />
            </div>
            : null}
      </Col>
      <Col xs='6' sm={{ span: 3, offset: 6 }}>
        {holidaycheckLink ?
          <div className='hc-logo'>
            <a href={holidaycheckLink}
               target="_blank"
               rel="nofollow noreferrer"
               onClick={handleHcLogoClick}
            >
              <img
                src={hcLogoUrl}
                alt="Holidaycheck Logo"
              />
            </a>
          </div>
          : <div className='hc-logo'>
            <img
              src={hcLogoUrl}
              alt="Holidaycheck Logo"
            />
          </div>

        }
      </Col>
    </StyledRow>
  )
}


export default StyledLogoRow