import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import logo from './160218_HC_Logo_16_bw_RGB.svg';
import './App.css';

import View from "./components/View";
import Review from "./components/Review";
import Atm from "./components/Atm";
import { createValidationSchema } from "./components/Review"
import { createAtmValidationSchema } from "./components/atm/AtmForm"

import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    blue: '#0058a3',
    linkblue: '#337ab7',
    gray: '#dfe2e6',
    green: '#54b13f',
    red: '#e8402d'
  },
  borders: {
    default: '1px solid #c2c7cc',
    inputHover: '1px solid #51a9e0'
  }
}

function App() {
  // create dummy if no real drupal context is available
  if (!window.drupalSettings) {
    console.log("creating dummy");
    window.drupalSettings = {
      "hcpw": {
        "action": "view",
        "holidaycheck_link": "http://www.holidaycheck.de/reise-Angebote.html",
        // Hotel-ID:
        // für fake-Bewertungen: 02a3eb53-2234-3879-a539-715f0438b16f
        // für Hotelier-Kommentar und Reisenachweis: 1aa4c4ad-f9ea-3367-a163-8a3a6884d450
        // für Renovierung: 1c500dc0-8f6e-45b2-beab-d3c70a1eb69f
        // Testdummy Hotel für review-Abgabe: c47efe9b-a888-3c8d-b315-53271cd00530
        // einfach ein Hotel: 7403a082-8388-3a03-a029-16f1cd86e005
        "hotel_id": "7403a082-8388-3a03-a029-16f1cd86e005",
        "href": "http://hcpw.docker.localhost:8000",
        "logo": "/sites/default/files/logos/5vF_GA_col_pos_RGB2.svg",
        "max_picture_count": 40,
        "max_review_count": 50,
        "pagination_reviews": 10,
        "partner_id": 993,
        "partner_link": "https://www.tojio.com",
        "show_all_reviews": "1",
        "submissions_allowed": "1",
        "title": "Urlaub_de",
        "partner": "tojio", // This ist the URL path part aka URL alias.
      },
      "path": {
        // Set current language manually when not called by Drupal backend.
        "currentLanguage": "de"
      },
      "user": {
        // Set permissions hash manually when not called by Drupal backend.
        "permissionsHash": "77f16756835fb737177e0266d699fec741f077e2fef65301fdf5b7819360385e"
      }
    }
  } else {
    // window.drupalSettings exists, but is window.drupalSettings.hcpw also set?
    if (!window.drupalSettings.hcpw) {
      // We're called from Drupal without any information => do nothing.
      return '';
    }
    // Change href to origin if we are not inside local npm server.
    // (href is used to get images URL from Drupal)
    if (window.location.port !== '3000') {
        window.drupalSettings.hcpw.href = window.location.origin;
    }
  }

  // Set dummy for hotel name.
  window.drupalSettings.hcpw.hotel_name = 'dummy';

  console.log(window.drupalSettings);

  // Loading component for suspense fallback.
  const Loader = () => (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div>loading...</div>
    </div>
  );

  // Set language ONCE.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    i18n.changeLanguage(window.drupalSettings.path.currentLanguage);
    // Re-Initialize the validationSchema to reflect correct language.
    // @see Review.js
    window.validationSchema = createValidationSchema();
    window.atmValidationSchema = createAtmValidationSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (window.drupalSettings.hcpw.action === "view") {
    return (
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <header className="App-header">
              <section>
                <View />
              </section>
            </header>
          </div>
        </ThemeProvider>
      </Suspense>
    );
  }
  else if (window.drupalSettings.hcpw.action === "review") {
    return (
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <header className="App-header">
              <section>
                <Review />
              </section>
            </header>
          </div>
        </ThemeProvider>
      </Suspense >
    );
  }
  else if (window.drupalSettings.hcpw.action === "atm") {
    return (
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <header className="App-header">
              <section>
                <Atm />
              </section>
            </header>
          </div>
        </ThemeProvider>
      </Suspense >
    );
  }

}

export default App;
