import { createGlobalStyle } from "styled-components";

import selectArrow from '../../selectpfeil.png'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    background-color: #f5f6f7;
  }

  html {
      font-size: 14px;
  }

  code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
  }

  a {
      text-decoration: none;
  }

  p {
      margin-bottom: 10px;
  }

  .hc-logo a {
    display: flex;
    justify-content: flex-end;
  }
  .validation-error {
    scroll-margin: 4rem;
  }
  .yellow-underline {
    padding: 4rem 0px 3rem;
    &::after {
      display: block;
      content: '';
      border-bottom: 0.21429rem solid #FAD73C;
      position: relative;
      width: 5rem;
      margin: 0 auto;
      bottom: -1.5rem;
    }
  }
  .filter {
    span {
      display: block;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
    select {
      all: unset;
      box-sizing: border-box;
      appearance: none;
      background-image: url(${selectArrow});
      background-position: 96% center;
      background-repeat: no-repeat no-repeat;
      background-size: 13px;
      border-radius: 0;
      border: 1px solid #cccccc;
      box-shadow: rgba(0,0,0,0.075) 0 1px 1px inset;
      box-shadow: none;
      color: #555;
      display: block;
      font-size: 16px;
      height: 40px;
      line-height: 1.42857;
      padding: 7px 30px 7px 1rem;
      text-transform: none;
      width: 300px;
      margin: auto;
      text-align: left;
    }
  }
  svg.ext {
    display: none;
  }
    /* Disable Zoom Click in photoswipe. */
  .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
  .pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
  .pswp__img {
      pointer-events: none;
  }
  
  .pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
      pointer-events: none;
  }
  
  /* Scale slide image without using width/heigth parameters in definition. */
  .pswp img {
      object-fit: contain;
      max-width: none;
      padding-left: 65px;
      padding-right: 65px;
  }
  
  /* Hide placeholder image in show slide. */
  .pswp__img--placeholder {
      display: none !important;
  }
  
  /* Dynamic caption for slide. */
  .pswp__dynamic-caption--below.pswp__dynamic-caption--on-hor-edge {
      left: 20px !important;
  }
  .tooltip .tooltip-inner {
    padding: 10px;
  }
  .tooltip[x-placement="left"] {
    .tooltip-inner {
      transform: translateX(4px);
    }
  }
  .tooltip[x-placement="bottom"] {
    .tooltip-inner {
      transform: translateY(-4px);
    }
  }

  .review-section {
    margin-bottom: 3rem;

    h3 {
      font-family: Open Sans,sans-serif;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .radio-options {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-wrap: wrap;

    & + .select-control select {
      margin-top: 1.5rem;
      width: 50%;
    }
  }
  .radio-option {
    text-align: center;
    border: 0.5px solid #c2c7cc;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: center;

    &:hover {
      color: #51a9e0;
      border-color: #51a9e0;
    }

    &.active {
      background: #FAE896;
      color: #665925;
      border-color: #dcc14c;

      &:hover {
        border-color: #dcc14c;
      }
    }

    input {
      display: none;
    }

    label {
      cursor: inherit;
      padding: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      span {
        order: 2;
      }

      i {
        order: 1;
        font-size: 2.85714rem;
        line-height: 1;
        margin-bottom: 5px;
      }
    }
  }
  textarea, input {
    width: 100%;
    outline: none;
    padding: 7px 1rem;
    border: 1px solid #c2c7cc;
    & + .help-paragraph {
      margin: 0;
      min-height: 3.14286rem;
      line-height: 3.14286rem;
      position: relative;
      padding: 0 1.5rem;
      color: #3e81ab;
      background-color: #f0f8fc;
      border: 1px solid #c2c7cc;
      transform: translateY(-6px);

      @media (max-width: 767px) {
        font-size: 0.79rem;
        line-height: calc(1.14rem + 6px);
        padding: 0.5rem 1.5rem;
        min-height: unset;
      }

      &::before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: -0.71429rem;
        left: 1.5rem;
        border-style: solid;
        border-width: 0 0.75rem 0.71429rem;
        border-color: transparent transparent #f0f8fc;
      }
    }
  }
  input, select {
    width: 100%;
    height: 40px;

    &:hover {
      border-color: #51a9e0;
    }

    & + .help-paragraph {
      transform: none;
      border-top: none;
    }

    &.validation-error {
      border-color: red;
    }
  }
  .t-center {
    text-align: center;
  }
  .select-control.validation-error {
    select {
      border-color: red;
    }
  }
  select {
    appearance: none;
    background-color: white;
    padding: 7px 1rem;
    color: #555;
    border: 1px solid #c2c7cc;
    outline: none;

    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .sun-rating label {
    padding: 0;

    i {
      margin-bottom: 0;
    }
  }
`

export default GlobalStyle