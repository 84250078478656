import React from 'react'
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field } from 'formik'
import styled from 'styled-components';
import RadioButtons from "./elements/RadioButtons";
import SunRating from "./elements/SunRating";
import TextArea from "./elements/TextArea";
import TextError from "./elements/TextError";

// Min length for review hotel general text.
export const minreviewtextlength = "100";

const StyledThumbRating = styled.div`
.radio-options {
  display: flex;
  justify-content: center;
  position: relative;
  background: white;

  .radio-option {
    flex-basis: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &.active {
      border-color: rgb(81, 169, 224);
    }

    &:first-of-type {
      color: ${props => props.theme.colors.green};

      &.active {
        background-color: ${props => props.theme.colors.green};
        color: white;
      }
    }
    &:last-of-type {
      color: ${props => props.theme.colors.red};

      &.active {
        background-color: ${props => props.theme.colors.red};
        color: white;
      }
    }

    label {
      padding: 0.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
      height: 77px;
      align-items: center;
    i {
      &::before {
        font-size: 3.5rem;
      }
    }
  }
  }
}
`

const StyledHotelGeneralSuns = styled.div`
  @media (max-width: 767px) {
    .sun-rating {
      flex-direction: column;

      .radio-options {
        border-right: 0;
      }
      & > div:last-child {
        width: 100%;
      }
      .sun-rating-text {
        border-top: 1px solid #edf0f2;
        padding: 0.57143rem;
        width: 100%;
      }
    }
  }
`

const StyledHotelGeneralText = styled.div`
  textarea {
    width: 100%;
    outline: none;
    height: 95px;
    resize: none;
    border: ${props => props.theme.borders.default};
    &:hover {
      border: ${props => props.theme.borders.inputHover};
    }
  }
  .help-paragraph {
    border-top: none;
    transform: translateY(-6px)
  }
`

function ThumbRating({ name, formikProps }) {
  const ratingRadioOptions = [
    { key: name + "true", value: "true", iconClass: "icon icon-like" },
    { key: name + "false", value: "false", iconClass: "icon icon-dislike" },
  ];

  const handleRadioButtons = event => {
    formikProps.handleChange(event);

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <StyledThumbRating>
      <RadioButtons
        label=''
        name={name}
        options={ratingRadioOptions}
        onChange={
          event => { handleRadioButtons(event); }
        }
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors[name] ? ' validation-error' : ''}
      />
      <ErrorMessage name={name} component={TextError} />
    </StyledThumbRating>
  );
}

export default function HotelGeneral({ formikProps }) {
  const { t } = useTranslation('common');

  return (
    <div className="hotelgeneral-wrapper">
      <div className="hotelgeneral-thumb review-section">
        <h3>{t('review.hotelgeneralrecommendation')} *</h3>
        <ThumbRating
          name="recommendation"
          formikProps={formikProps}
        />
      </div>
      <StyledHotelGeneralSuns className="hotelgeneral-suns review-section">
        <h3>{t('review.hotelgeneralrating')} *</h3>
        <SunRating
          name="valuations.GENERAL.rating"
          formikProps={formikProps}
        />
      </StyledHotelGeneralSuns>
      <StyledHotelGeneralText className="hotelgeneral-text review-section">
        <h3>{t('review.hotelgeneraldescription')} *</h3>
        <Field
          label=""
          component={TextArea}
          name="valuations.GENERAL.description"
          rows="4"
          help={t('review.hotelgeneralhelp', { minreviewtextlength: minreviewtextlength })}
        />
      </StyledHotelGeneralText>
    </div>
  )
}
