import styled from 'styled-components';

const StyledMainButton = styled.div`
    display: flex;
    justify-content: flex-end;
    a, button {
      font-family: "Open Sans",sans-serif;
      padding: 1.07143rem 1rem 0.92857rem;
      font-size: 1.43rem;
      line-height: 1;
      border: none;
      border-radius: 0;
      color: #0058a3;
      background-color: ${props => props.gray ? '#dfe2e6' : '#fad73c'};
      border-bottom: 4px solid;
      border-bottom-color: ${props => props.gray ? '#adadad' : '#d9bd43'};
      box-shadow: none;
      font-weight: 600;
      flex-basis: content;
      text-align: right;
      display: inline-block;
    
      &:hover {
        background-color: #51a9e0;
        color: white !important;
        border-bottom-color: #3e81ab;
      }
    }
  `

export default StyledMainButton