import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "./Select";
import RadioButtons from "./RadioButtons";
import TextError from "./TextError";
import { ErrorMessage } from "formik";

export default function TravelDuration({ formikProps }) {
  const { t } = useTranslation('common');
  const [showDurationSelect, setShowDurationSelect] = useState(false);

  const durationRadioOptions = [
    { key: t('review.durationonetothree'), value: 'ONE_TO_THREE_DAYS', displayLabel: true },
    { key: t('review.durationthreetofive'), value: 'THREE_TO_FIVE_DAYS', displayLabel: true },
    { key: t('review.durationoneweek'), value: 'ONE_WEEK', displayLabel: true },
    { key: t('review.durationtwoweeks'), value: 'TWO_WEEKS', displayLabel: true },
    { key: t('review.durationmorethantwoweeks'), value: 'MORE_THAN_TWO_WEEKS', displayLabel: true }
  ];

  const durationSelectOptions = [
    { key: t('review.durationchoose'), value: '' },
    { key: t('review.durationthreeweeks'), value: 'THREE_WEEKS' },
    { key: t('review.durationfourweeks'), value: 'FOUR_WEEKS' },
    { key: t('review.durationfiveweeks'), value: 'FIVE_WEEKS' },
    { key: t('review.durationlonger'), value: 'LONGER' }
  ];

  const handleRadioButtons = event => {
    formikProps.handleChange(event);
    if (event.target.value === 'MORE_THAN_TWO_WEEKS') {
      setShowDurationSelect(true);
    }
    else {
      setShowDurationSelect(false);
    }

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.radio-options');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <div className="travelduration-wrapper review-section">
      <h3>{t('review.durationtitle')} *</h3>
      <RadioButtons
        label=''
        buttonsperrow={5}
        name='travelDuration'
        options={durationRadioOptions}
        onChange={
          event => { handleRadioButtons(event); }
        }
        style={{ visibility: "hidden", height: "0", width: "0" }}
        hasErrorClass={formikProps.errors.travelDuration ? ' validation-error' : ''}
      />
      <ErrorMessage name="travelDuration" component={TextError} />
      {showDurationSelect ?
        <>
          <Select
            control="select"
            label=""
            name='durationSelectOption'
            options={durationSelectOptions}
            hasErrorClass={formikProps.errors.durationSelectOption ? ' validation-error' : ''}
          />
        </>
        : null}
    </div>
  )
}
