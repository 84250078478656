import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Stack from 'react-bootstrap/esm/Stack';
import Tooltip from 'react-bootstrap/Tooltip';

import { MapAgeGroup, MapTravelDate } from "../common/Mappings";
import { Recommendation } from "./Recommendation";
import { CollapsibleReview } from "./CollapsibleReview";
import { ReviewDetails } from "./ReviewDetails";

import { ReactComponent as RenovationInfoIcon } from '../../../src/renovation-info.svg'
import { ReactComponent as CheckmarkIcon } from '../../../src/checkmark.svg'

import Col from 'react-bootstrap/esm/Col';

const StyledReview = styled.div`
  margin-top: 2.5rem;
  padding: 1.5rem 0;
  background-color: white;
  border-bottom: 2px solid #dfe2e6;

  .renovation-hint {
    text-align: right;
  }

  .owner-comment {
    text-align: left;
    background-color: rgb(243, 247, 253);
    padding: 24px;
    margin-bottom: 1.5rem;

    > p:first-of-type {
      font-weight: bold;
    }
  }

  .review-title {
    font-style: italic;
    font-family: "Open Sans",sans-serif;
    font-weight: 600;
    font-size: 1.143rem;
    line-height: 1.5rem;
    max-width: 90%;
    margin: 0 auto;
    color: #333;
  }
  .review-text {
    margin-top: 1rem;
    text-align: left;

    &.faded {
      max-height: 4.3rem;
      overflow: hidden;
      position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0rem;
          left: 100%;
          height: 65%;
          width: 100%;
          margin-left: -100%;
          padding-right: 5px;
          background: linear-gradient(180deg,rgba(255,255,255,0) 0,rgba(255,255,255,0.5) 1rem,#fff 3.5rem,#fff);
        }
      }
    }

  .review-author {
    font-size: 12px;
  }

  .review-date {
    font-size: 12px;
  }

  .hotel-info-recommendation {
    justify-content: center;
    .rating-author {
      padding-left: 1rem;
      padding-right: 1rem;
      color: white;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      
      i::before {
        font-size: 2rem;
      }

      .icon-like {
        font-size: 24px;
      }
    }
    .rating-author.green {
      background-color: ${props => props.theme.colors.green};
    }
    .rating-author.red {
      background-color: ${props => props.theme.colors.red};
    }
    .hstack {
      border: 1px solid ${props => props.theme.colors.gray};
      padding: 4px 14px;
      -webkit-tap-highlight-color: transparent;
      display: flex;
      font-size: 1.8rem;
      line-height: 1.95rem;
      padding: 0 0 0.21429rem 0.71429rem;
      vertical-align: top;
      padding-bottom: 0;

      .rating-suns {
        padding-right: 0;
      }

      .rating-suns + span {
        font-size: 1rem;
        font-weight: 600;
        line-height: 2.14286rem;
        padding: 0 0.71429rem;
      }
    }
  }

  @media (max-width: 767px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
const StyledRenovationNotice = styled(Stack)`
  align-items: center;
  padding-right: 24px;
  margin-bottom: 10px;
  justify-content: flex-end;
  margin-left: auto;
  max-width: fit-content;

  p {
    margin: 0;
    padding-left: 4px;
  }

  @media (max-width: 767px) {
    padding-right: 0;
  }
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: #8493b2;
  height: 20px;
  width: 20px;
`

const StyledReviewInfo = styled(Stack)`
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #000820;

  .hstack {
    margin: 0 4px;
  }

  p:first-of-type {
    font-size: 12px;
    margin-left: 4px;
    margin-top: 14px;
  }
`

const StyledTooltip = styled(Tooltip)`
  padding: 10px;
  opacity: 0.5;
`

export default function SingleReview({ review }) {

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(prev => !prev)
  }

  let showRenovation = null;
  if (window.drupalSettings.hcpw.renovation_date &&
    review.travelDate < window.drupalSettings.hcpw.renovation_date) {
    showRenovation = true;
    if (!window.drupalSettings.hcpw.renovation_description) {
      // Insert placeholder, when there are no further infos.
      window.drupalSettings.hcpw.renovation_description = t('viewreviews.renovationinfo') + ': ' + MapTravelDate(window.drupalSettings.hcpw.renovation_date);
    }
  }

  // Workaround for "main review text": @see https://tojio.teamwork.com/app/tasks/24197024
  let mainReviewText = '';
  if (review.texts) {
    if (review.texts.GENERAL) {
      mainReviewText = review.texts.GENERAL;
      review.texts.useHOTEL = true;
    } else if (review.texts.HOTEL) {
      mainReviewText = review.texts.HOTEL;
      review.texts.useHOTEL = false;
    }
  }

  return (
    <StyledReview>
      {showRenovation ?
        <OverlayTrigger
          key='renovation'
          placement={windowSize < 768 ? 'bottom' : 'left'}
          overlay={
            <StyledTooltip id='renovation-tooltip'>
              {window.drupalSettings.hcpw.renovation_description}
            </StyledTooltip>
          }
        >
          <StyledRenovationNotice direction='horizontal'>
            <RenovationInfoIcon />
            <p className='renovation-hint'>
              {t('viewreviews.renovationdescription')}
            </p>
          </StyledRenovationNotice>
        </OverlayTrigger>
        : null}
      <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
        <p className="review-author">
          <strong>{review.user.firstName}</strong>
          { review.user.ageGroup ?
            <span>
              &nbsp;({MapAgeGroup(review.user.ageGroup)})
            </span>
            : null}
        </p>
        <p className="review-date">
          {MapTravelDate(review.travelDate)}
        </p>
        <p className="review-title">
          <b>{review.title}</b>
        </p>
        <Recommendation
          Recommendation={review.recommendation}
          Rating={review.ratings.GENERAL.GENERAL}
          percentage={false}
        />

        <StyledReviewInfo direction='horizontal'>
          {review.proofedReservation ?
            <OverlayTrigger
              key='renovation'
              placement={windowSize < 768 ? 'bottom' : 'left'}
              overlay={
                <StyledTooltip id='proof-tooltip'>
                  {t('viewreviews.proofedtooltip')}
                </StyledTooltip>
              }
            >
              <Stack direction='horizontal'>
                <StyledCheckmarkIcon />
                <p className="proofed-reservation">
                  {t('viewreviews.proofed')}
                </p>
              </Stack>
            </OverlayTrigger>
            : null}
          {review.ownerComment ?
            <Stack direction='horizontal'>
              <StyledCheckmarkIcon />
              <p className="owner-comment-present">
                {t('viewreviews.ownercomment')}
              </p>
            </Stack>
            : null}
        </StyledReviewInfo>
        <p className={`review-text ${open === false ? "faded" : ""}`}
           dangerouslySetInnerHTML={{ __html: mainReviewText.replace(/\n/g, "<br />") }}
        />
        {open && <hr className="horizontal-line"></hr>}
        <CollapsibleReview
          handleOpen={handleOpen}
          review={
            <ReviewDetails
              ReviewRatings={review.ratings}
              ReviewTexts={review.texts}
            />
          }
          ownerComment={review.ownerComment && review.ownerComment.text ?
            <div className="owner-comment">
              <p>{t('viewreviews.ownercommenttitle')}</p>
              <p dangerouslySetInnerHTML={{ __html: review.ownerComment.text.replace(/\n/g, "<br />") }} />
            </div>
            : null}
        />
      </Col>
    </StyledReview>
  )
}