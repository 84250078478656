import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import TextError from "./TextError";
import RadioButtons from "./RadioButtons";
import styled from "styled-components";

const StyledAge = styled.div`
    .agegroup1 {
      .validation-error {
        .radio-option {
          border-bottom-color: rgb(194, 199, 204);

          &:hover {
            border-bottom-color: #51a9e0;
          }
        }
      }
    }
    .agegroup2 {
      .validation-error {
        .radio-option {
          border-top-color: rgb(194, 199, 204);

          &:hover {
            border-top-color: #51a9e0;
          }
        }
      }
    }
`

export default function Age({ formikProps }) {
  const { t } = useTranslation('common');

  const handleRadioButtons = event => {
    formikProps.handleChange(event);

    // Get Wrapper with all radios inside.
    let wrapperDiv = event.target.closest('.agegroup-wrapper');
    // Get all radios.
    let radios = wrapperDiv.querySelectorAll('input[type=radio]');
    // Remove class "active" from all radio divs.
    radios.forEach((item) => item.parentElement.classList.remove("active"));
    // Set class active for clicked div.
    event.target.parentElement.classList.toggle('active');
  }

  return (
    <StyledAge className="agegroup-wrapper review-section">
      <h3>{t('review.agetitle')} *</h3>
      <div className="agegroup1">
        <RadioButtons
          label=""
          name="ageGroup"
          buttonsperrow={6}
          options={[
            { key: '14-18', value: 'FROM_14_TO_18', displayLabel: true },
            { key: '19-25', value: 'FROM_19_TO_25', displayLabel: true },
            { key: '26-30', value: 'FROM_26_TO_30', displayLabel: true },
            { key: '31-35', value: 'FROM_31_TO_35', displayLabel: true },
            { key: '36-40', value: 'FROM_36_TO_40', displayLabel: true },
            { key: '41-45', value: 'FROM_41_TO_45', displayLabel: true },
          ]}
          onChange={event => {
            handleRadioButtons(event);
          }}
          style={{ visibility: "hidden", height: "0", width: "0" }}
          hasErrorClass={formikProps.errors.ageGroup ? ' validation-error' : ''}
        />
      </div>
      <div className="agegroup2">
        <RadioButtons
          label=""
          buttonsperrow={6}
          name="ageGroup"
          options={[
            { key: '46-50', value: 'FROM_46_TO_50', displayLabel: true },
            { key: '51-55', value: 'FROM_51_TO_55', displayLabel: true },
            { key: '56-60', value: 'FROM_56_TO_60', displayLabel: true },
            { key: '61-65', value: 'FROM_61_TO_65', displayLabel: true },
            { key: '66-70', value: 'FROM_66_TO_70', displayLabel: true },
            { key: '70 >', value: 'FROM_71', displayLabel: true },
          ]}
          onChange={event => {
            handleRadioButtons(event);
          }}
          style={{ visibility: "hidden", height: "0", width: "0" }}
          hasErrorClass={formikProps.errors.ageGroup ? ' validation-error' : ''}
        />
      </div>
      <ErrorMessage name="ageGroup" component={TextError} />
    </StyledAge>
  )
}
