import React from "react";
import { useTranslation } from 'react-i18next';

import { RecommendationSuns } from "./RecommendationSuns";
import styled from "styled-components";
import Stack from "react-bootstrap/esm/Stack";

const StyledReviewRating = styled.div`
  text-align: left;
  .hstack {
    justify-content: space-between;
  }
  .rating-suns {
    font-size: 1.71429rem;
  }
  hr {
    margin: 20px 0;
  }
  h4 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
  }
  p {
    margin-top: 0.5rem;
  }
`

function ReviewCategory({ CategoryName, Rating, Text }) {
  return (
    <StyledReviewRating className='review-rating'>
      <Stack direction="horizontal">
        <h4>{CategoryName}</h4>
        { Rating ?
        <RecommendationSuns
          Rating={Rating}
          showMaxRating={false}
        />
        : null }
      </Stack>
      {Text && <p dangerouslySetInnerHTML={{ __html: Text.replace(/\n/g, "<br />") }} />}
      <hr className="horizontal-line"></hr>
    </StyledReviewRating>
  );
}

export function ReviewDetails({ ReviewRatings, ReviewTexts }) {
  const { t } = useTranslation('common');

  return (
    <div className='review-ratings'>
      {(ReviewRatings.LOCATION && ReviewRatings.LOCATION.GENERAL) || ReviewTexts.LOCATION ?
        <ReviewCategory
          CategoryName={t('viewreviews.location')}
          Rating={ReviewRatings.LOCATION ? ReviewRatings.LOCATION.GENERAL : null}
          Text={ReviewTexts.LOCATION ? ReviewTexts.LOCATION : null}
        /> : null}
      {(ReviewRatings.ROOM && ReviewRatings.ROOM.GENERAL) || ReviewTexts.ROOM ?
        <ReviewCategory
          CategoryName={t('viewreviews.room')}
          Rating={ReviewRatings.ROOM ? ReviewRatings.ROOM.GENERAL : null}
          Text={ReviewTexts.ROOM ? ReviewTexts.ROOM : null}
        /> : null}
      {(ReviewRatings.SERVICE && ReviewRatings.SERVICE.GENERAL) || ReviewTexts.SERVICE ?
        <ReviewCategory
          CategoryName={t('viewreviews.service')}
          Rating={ReviewRatings.SERVICE ? ReviewRatings.SERVICE.GENERAL : null}
          Text={ReviewTexts.SERVICE ? ReviewTexts.SERVICE : null}
        /> : null}
      {(ReviewRatings.FOOD && ReviewRatings.FOOD.GENERAL) || ReviewTexts.FOOD ?
        <ReviewCategory
          CategoryName={t('viewreviews.food')}
          Rating={ReviewRatings.FOOD ? ReviewRatings.FOOD.GENERAL : null}
          Text={ReviewTexts.FOOD ? ReviewTexts.FOOD : null}
        /> : null}
      {(ReviewRatings.SPORT && ReviewRatings.SPORT.GENERAL) || ReviewTexts.SPORT ?
        <ReviewCategory
          CategoryName={t('viewreviews.sport')}
          Rating={ReviewRatings.SPORT ? ReviewRatings.SPORT.GENERAL : null}
          Text={ReviewTexts.SPORT ? ReviewTexts.SPORT : null}
        /> : null}
      {(ReviewRatings.HOTEL && ReviewRatings.HOTEL.GENERAL) || ReviewTexts.HOTEL ?
        <ReviewCategory
          CategoryName={t('viewreviews.hotel')}
          Rating={ReviewRatings.HOTEL ? ReviewRatings.HOTEL.GENERAL : null}
          Text={ReviewTexts.useHOTEL && ReviewTexts.HOTEL}
        /> : null}
    </div>
  );
}