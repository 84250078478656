import React from "react";
import { useTranslation } from "react-i18next";

export default function TandC() {
  const { t } = useTranslation('common');

  let privacyUrl = '//www.holidaycheck.de/datenschutz';
  if (window.drupalSettings.path.currentLanguage === 'en') {
    privacyUrl = privacyUrl + '?lang=en';
  }

  return (
    <div className="tandc-wrapper t-center">
      <p className="tandc">
        {t('review.tandctext1')} <br />
        {t('review.tandctext2')} <br />
        {t('review.tandctext3')}
        <a
          href={privacyUrl}
          target="_blank"
          rel="noreferrer"
        >
          &nbsp;{t('review.tandctextlink')}
        </a>
        .
      </p>
    </div>
  );
}
