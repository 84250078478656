import React from "react";
import { APIHotelContextProvider, APIReviewContextProvider, APIMediaContextProvider } from "./common/ApiConnector"

import { ViewHeader } from "./view/ViewHeader";
import { ViewReviews } from "./view/ViewReviews";
import { ViewImages } from "./view/ViewImages";
import { ViewFooter } from "./common/ViewFooter";

import Container from 'react-bootstrap/Container'

export default function View() {
  return (
    <Container fluid="md">
      <section>
        <APIHotelContextProvider>
          <ViewHeader />
        </APIHotelContextProvider>
      </section>
      <section>
        <APIReviewContextProvider>
          <ViewReviews />
        </APIReviewContextProvider>
      </section>
      <section>
        <APIMediaContextProvider>
          <ViewImages />
        </APIMediaContextProvider>
      </section>
      <section>
        <ViewFooter />
      </section>
    </Container>
  );
}
